import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styles from "./Container.module.css";
import styles1 from "./FrameComponent.module.css";
import {
  setActiveThread,
  setLeftOpen,
  setMessageToThread,
  setThreads,
} from "../../Redux/reducers/LLM";
import CreateThreadModal from "../../Modals/CreateThreadModal";
import { ToastNotification } from "../../Utils/ToastNotifications";
import { createThread, getAllThreads, lambdaCallForDemo, updatethreadContent } from "../../Api/API";
import { ArrowUpDown, Collapse, LLMHome, LLMModals, LLMNewThread, LLMSetting, LLMSyncs, LLMThread } from "../../SVG";

const Container = ({ className = "" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [openModal, setOpenModal] = useState(false);
  const { threads, activeThread, leftOpen } = useSelector((state) => state.llm);
  const { userDetail } = useSelector((state) => state.users);

  const [textAreaValue, setTextAreaValue] = useState("");

  const [statusMenu, setStatusMenu] = useState(null);
  const open = Boolean(statusMenu);

  const handleClick = (event) => {
    setStatusMenu(event.currentTarget);
  };

  const handleClose = () => {
    setStatusMenu(null);
  };

  const handleAddNewThread = () => {
    setOpenModal(true);
    // dispatch(
    //   setThreads({ name: "check new this thread", id: threads.length + 1 })
    // );
  };
  const handleThread = async () => {
    if (textAreaValue.length < 4) {
      ToastNotification("info", "Please enter at least 3 characters");
      return;
    }

    let activeThread = {};
    let data = {
      name: textAreaValue,
      content: [
        {
          user: "user",
          message: textAreaValue,
        },
      ],
    };

    try {
      // Create a new thread
      const createRes = await createThread(data);

      if (createRes.success === true) {
        // dispatch(updateThread(createRes.data));
        // console.log(createRes.data, "Thread created successfully");

        // Fetch all threads after creating a new thread
        const threadsRes = await getAllThreads(userDetail.user.id);

        if (threadsRes.data) {
          console.log("Fetched all threads:", threadsRes.data);

          activeThread = threadsRes.data[threadsRes.data.length - 1]; // Set the latest thread as active
          console.log(activeThread, "active here");

          dispatch(setThreads(threadsRes.data));
          dispatch(setActiveThread(activeThread));
        }
      }

      setTextAreaValue("");
      setOpenModal(false);
      navigate("/llm-tender-thread");

      const body = {
        message: textAreaValue,
      };

      // Make a call to lambda function
      const lambdaResponse = await lambdaCallForDemo(body);

      if (lambdaResponse.data) {
        // console.log(activeThread, "activeThread");
        const threadId = activeThread?.id; // Use the correct ID

        let data = {
          newMessage: {
            user: "assistant",
            message: lambdaResponse?.data?.content,
          },
        };

        // console.log(activeThread.id, "threadId");

        // Update the thread content with the assistant's message
        const updateRes = await updatethreadContent(threadId, data);

        if (updateRes.success) {
          console.log("Thread content updated successfully");
        }

        // Update the Redux store with the new message
        dispatch(
          setMessageToThread({
            threadId: threadId,
            newMessage: {
              user: "assistant",
              message: lambdaResponse?.data?.content,
            },
          })
        );
      }
    } catch (err) {
      console.log("Error occurred:", err);
    }
  };
  const handleThreadClick = (thread) => {
    dispatch(setActiveThread(thread));
    navigate("/llm-tender-thread");
  };

  const handleGenClick = () => {
    navigate("/llm");
    handleClose()
  };

  const getAllThreadsFromAPI = () => {
    getAllThreads(userDetail.user.id)
      .then((res) => {
        console.log(res.data, "threads");
        if (res.data) {
          dispatch(setThreads(res.data));
          console.log(threads);
          // dispatch(setActiveThread(res.data?.[0]));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllThreadsFromAPI();
  }, []);

  return (
    <div className={[styles.container, className].join(" ")}
      style={{
        width: leftOpen ? '239px' : '58px',
        overflowX: !leftOpen ? 'hidden' : ''
      }}
    >
      <div className={styles1.horizontalborder}>
        {leftOpen && (
          <div
            className={styles1.comboboxDialogSelectAnOr}
            style={{ cursor: 'pointer' }}
            onClick={handleClick}>
            <div className={styles1.container}>
              <div className={styles1.genAi}>Tender AI</div>
            </div>
            <ArrowUpDown />
          </div>
        )}
        <div
          className={styles1.svgIcon}
          style={{ cursor: "pointer", padding: !leftOpen ? "12px" : "" }}
          onClick={() => dispatch(setLeftOpen(!leftOpen))}
        >
          <Collapse />
        </div>

      </div>
      <Link to="/llm-tender" className={styles.home}>
        <div className={styles.container1}
          style={{ cursor: 'pointer', width: leftOpen ? '220px' : '' }}
          // onClick={handleAddNewThread}
        >
          <div className={styles.buttonDialog}>
            <div className={styles.svgParent}>
              <div className={styles.svg}>
                <LLMNewThread />
              </div>
              {leftOpen && (
                <div className={styles.container2}>
                  <div
                    className={styles.newThreadWrapper}
                  >
                    <h2 className={styles.newThread}>New thread</h2>
                  </div>
                </div>
              )}
            </div>
            {leftOpen && (
              <div className={styles.container3}>
                <div className={styles.overlaybordershadow}>
                  <h2 className={styles.h2}>⌘</h2>
                </div>
                <div className={styles.overlaybordershadow1}>
                  <h3 className={styles.k}>K</h3>
                </div>
              </div>
            )}
          </div>
        </div>
      </Link>
      <div className={styles.container4}>
        <div
          className={styles.button}
          onClick={() => dispatch(setActiveThread({}))}
        >
          <Link to="/llm-tender" className={styles.home}>
            <div
              className={styles.link}
              style={{
                background: location.pathname === "/llm-tender" ? "#e5e5e5" : "",
                width: leftOpen ? '175px' : ''
              }}
            >
              <div className={styles.svg}>
                <LLMHome />
              </div>
              {leftOpen && (
                <div className={styles.linkLabels}>Home</div>
              )}
            </div>
          </Link>
        </div>
        <div className={styles.button}>
          <div className={styles.link1}>
            <div className={styles.svg}>
              <LLMSyncs />
            </div>
            {leftOpen && (
              <h2 className={styles.syncs}>Syncs</h2>
            )}
          </div>
        </div>
        <div className={styles.button}>
          <div className={styles.link2}>
            <div className={styles.svg}>
              <LLMModals />
            </div>
            {leftOpen && (
              <h2 className={styles.syncs}>Models</h2>
            )}
          </div>
        </div>
      </div>
      <div className={styles.container5}>
        <div className={styles.horizontalDivider} />
      </div>
      <div className={styles.container6}>
        <div
          className={styles.button}
          onClick={() => dispatch(setActiveThread({}))}
        >
          <Link to="/llm-tender-threadlist" className={styles.home}>
            <div
              className={styles.link3}
              style={{
                background:
                  location.pathname === "/llm-tender-threadlist" ? "#e5e5e5" : "",
                width: leftOpen ? '175px' : ''
              }}
            >
              <div className={styles.svg}>
                <LLMThread />
              </div>
              {leftOpen && (
                <h2 className={styles.syncs}>Threads</h2>
              )}
            </div>
          </Link>
        </div>
        {leftOpen && (
          [...threads]?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))?.map((item, index) => {
            return (
              <div
                className={styles.link4}
                key={index}
                onClick={() => handleThreadClick(item)}
                style={{
                  backgroundColor:
                    item.id === activeThread?.[0]?.id ? "#e5e5e5" : "#F8F8F8",
                  cursor: "pointer",
                }}
              >
                <h2 className={styles.checkTheseThree}>{item.name}</h2>
              </div>
            );
          }))}
        <CreateThreadModal
          open={openModal}
          setOpen={setOpenModal}
          handleThread={handleThread}
          textAreaValue={textAreaValue}
          setTextAreaValue={setTextAreaValue}
        />
      </div>
      <div className={styles.margin}>
        <div className={styles.container7}>
          <div className={styles.button4}>
            <div className={styles.link5}>
              <div className={styles.svgmargin}>
                <div className={styles.svg}>
                  <LLMSetting />
                </div>
              </div>
              {leftOpen && (
                <h2 className={styles.settings}>Settings</h2>
              )}
            </div>
          </div>
        </div>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={statusMenu}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            zIndex: 99999999,
            width: '200px'
          },
        }}
      >
        <MenuItem
          onClick={handleGenClick}
        >
          Gen AI
        </MenuItem>
        <MenuItem>
          Tender AI
        </MenuItem>
      </Menu>
    </div>
  );
};

Container.propTypes = {
  className: PropTypes.string,
};

export default Container;
