import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styles from "./FrameComponent1.module.css";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Copy,
  LoadingSpinner,
  MagicWand01New,
  SearchIcon,
  // Speaker,
  Subtract1,
  ThumbDown,
  ThumbUp,
} from "../../SVG";
import ChatLoader from "./ChatLoader";
// import ChatArea from "./ChatArea";
import { formatApiResponse } from "../../Utils/GptTextFormat";
import DataTable from "./DataTable";
import { ToastNotification } from "../../Utils/ToastNotifications";
import ChatTableModal from "../../Modals/ChatTable";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import DownloadIcon from "@mui/icons-material/Download";

const FrameComponent1 = ({ className = "" }) => {
  const { activeThread } = useSelector((state) => state.llm);
  const [selectedItem, setSelectedItem] = useState("");
  const [likedMessages, setLikedMessages] = useState([]);
  const [dislikedMessages, setDislikedMessages] = useState([]);
  const [isTableModalOpen, setIsTableModalOpen] = useState(false);

  const handleShowSteps = (index) => {
    console.log(selectedItem, "asasas", index);
    if (selectedItem === index) {
      setSelectedItem("");
    } else {
      setSelectedItem(index);
    }
  };

  const handleLike = (id) => {
    setLikedMessages((prevLiked) => {
      // Toggle the like status
      if (prevLiked.includes(id)) {
        return prevLiked.filter((messageId) => messageId !== id); // Remove like
      } else {
        // Add to liked and remove from disliked
        setDislikedMessages((prevDisliked) =>
          prevDisliked.filter((messageId) => messageId !== id)
        );
        return [...prevLiked, id]; // Add like
      }
    });
  };

  const handleDislike = (id) => {
    setDislikedMessages((prevDisliked) => {
      // Toggle the dislike status
      if (prevDisliked.includes(id)) {
        return prevDisliked.filter((messageId) => messageId !== id); // Remove dislike
      } else {
        // Add to disliked and remove from liked
        setLikedMessages((prevLiked) =>
          prevLiked.filter((messageId) => messageId !== id)
        );
        return [...prevDisliked, id]; // Add dislike
      }
    });
  };

  const downloadCSV = (data, filename = "data.csv") => {
    // Step 1: Convert array of objects to CSV string
    const csvData = convertToCSV(data);

    // Step 2: Create a Blob from the CSV string
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

    // Step 3: Create a link element and trigger the download
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";

    // Append the link to the body and trigger the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const convertToCSV = (data) => {
    if (!data || !data.length) return "";

    const headers = Object.keys(data[0]); // Extract object keys for the header
    const csvRows = [];

    // Add the headers as the first row
    csvRows.push(headers.join(","));

    // Loop through the array and convert each object to a CSV row
    for (const row of data) {
      const values = headers.map((header) => {
        const escapedValue = ("" + row[header]).replace(/"/g, '""'); // Escape double quotes
        return `"${escapedValue}"`; // Wrap each value in double quotes
      });
      csvRows.push(values.join(","));
    }

    // Return the CSV string
    return csvRows.join("\n");
  };

  const handleDownload = (data) => {
    downloadCSV(data, "VQ_LLM_Table_data.csv");
  };

  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1500);
  // }, []);

  return (
    <div className={[styles.frameParent11, className].join(" ")}>
      <div style={{ width: "768px" }}>
        {activeThread?.[0]?.content?.map((item, index, array) => {
          const { formattedText, tableData } = formatApiResponse(item?.message);
          const isLastMessage = index === array.length - 1;
          const lastMessage =
            activeThread?.[0]?.content?.[activeThread[0].content.length - 1];
          console.log(lastMessage, "last message");
          return (
            <>
              {item.user === "user" && (
                <div className={styles.documentContainerWrapper}>
                  <div className={styles.documentContainer}>
                    <div className={styles.documentHeader}>
                      <div className={styles.documentTitle}>
                        <h3 className={styles.vq}>VQ</h3>
                      </div>
                      <h1 className={styles.checkTheseThree}>{item.message}</h1>
                    </div>
                    <div className={styles.filesParent}>
                      <div className={styles.files}></div>
                      <div className={styles.frameWrapper}>
                        <div className={styles.frameChild} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {item.user === "assistant" && (
                <>
                  <div className={styles.resultsContainerParent}>
                    <div className={styles.resultsContainer}>
                      <div className={styles.resultsHeader}>
                        <div className={styles.overlaybordershadow}>
                          <Subtract1 area="14" />
                        </div>
                        <div className={styles.resultsWrapper}>
                          <h1 className={styles.results}>Results</h1>
                        </div>
                      </div>
                      <div className={styles.resultsContainer1}>
                        <MagicWand01New />
                        <div
                          className={styles.resultsWrapper}
                          onClick={() => handleShowSteps(index)}
                        >
                          <h2 className={styles.showSteps}>
                            {" "}
                            {selectedItem === index
                              ? "Hide steps"
                              : "Show steps"}
                          </h2>
                        </div>
                      </div>
                    </div>
                    {selectedItem === index && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#f9f9f9",
                          borderRadius: "8px",
                          padding: "10px",
                          width: "100%",
                          maxWidth: "685px",
                          border: "1px solid #e0e0e0",
                          marginLeft: "46px",
                          // justifyContent:'flex-end',
                          gap: 1,
                        }}
                      >
                        <SearchIcon
                          sx={{ marginRight: "25px", color: "#616161" }}
                        />
                        <Typography variant="body1" color="textPrimary">
                          Searching examples and definitions
                        </Typography>
                      </Box>
                    )}

                    <div className={styles.queryContainer}>
                      <h1 className={styles.iSeeThat}>
                        {/* <ChatArea data={item.message}/> */}
                        {formattedText && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: formattedText,
                            }}
                          />
                        )}
                        {tableData?.length > 0 && (
                          <>
                            <div className={styles.TableIcons}>
                              <div style={{ cursor: "pointer" }}>
                                <DownloadIcon
                                  fontSize="medium"
                                  onClick={()=>handleDownload(tableData)}
                                />
                              </div>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => setIsTableModalOpen(true)}
                              >
                                <FullscreenIcon fontSize="medium" />
                              </div>
                            </div>
                            <DataTable data={tableData} />
                          </>
                        )}
                        {tableData?.length > 0 && isTableModalOpen && (
                          <ChatTableModal
                            data={tableData}
                            isOpen={isTableModalOpen}
                            setIsTableModalOpen={setIsTableModalOpen}
                            downloadCSV={handleDownload}
                          />
                        )}
                      </h1>
                    </div>

                    <div className={styles.queryContainer}>
                      <h1 className={styles.iSeeThat}>
                        {/* <Speaker /> */}
                        <div className={styles.iconContainer}>
                          <CopyToClipboard
                            text={formattedText}
                            onCopy={() =>
                              ToastNotification(
                                "success",
                                "Copied to clipboard"
                              )
                            }
                          >
                            <div
                              className={styles.hoverIcon}
                              style={{
                                paddingBottom: "0px",
                                paddingLeft: "0px",
                              }}
                            >
                              <Copy />
                            </div>
                          </CopyToClipboard>
                          <div
                            className={styles.hoverIcon}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleLike(index)}
                          >
                            <ThumbUp
                              color={
                                likedMessages.includes(index)
                                  ? "#13D92A"
                                  : "none"
                              }
                            />
                          </div>
                          <div
                            className={styles.hoverIcon}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDislike(index)}
                          >
                            <ThumbDown
                              color={
                                dislikedMessages.includes(index)
                                  ? "#1F443A"
                                  : "none"
                              }
                            />
                          </div>
                        </div>
                      </h1>
                    </div>
                  </div>
                </>
              )}
              {isLastMessage && lastMessage.user == "user" && (
                <div className={styles.loadingState}>
                  <Box
                    sx={{
                      backgroundColor: "#f9f9f9",
                      borderRadius: "8px",
                      padding: "10px",
                      width: "100%",
                      maxWidth: "685px",
                      border: "1px solid #e0e0e0",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        maxWidth: "685px",
                        gap: 10,
                        marginBottom: "10px",
                      }}
                    >
                      <SearchIcon
                        sx={{ marginRight: "15px", color: "#616161" }}
                      />
                      <Typography variant="body1" color="textPrimary">
                        Searching examples and definitions
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        maxWidth: "685px",
                        gap: 10,
                      }}
                    >
                      <LoadingSpinner />
                      <Typography variant="body1" color="textPrimary">
                        Considering sources
                      </Typography>
                    </div>
                  </Box>
                  <div className={styles.pulseContainer}>
                    <ChatLoader />
                    {/* <div className={styles.pulseBar}></div>
                    <div className={styles.pulseBar}></div>
                    <div className={styles.pulseBar}></div> */}
                  </div>
                </div>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
