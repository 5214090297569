import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Close } from "../../SVG";
import FolderIcon from "@mui/icons-material/Folder";
import styles from "./styles.module.scss";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import DownloadIcon from "@mui/icons-material/Download";

// Modal content container style
const modalStyle = {
  width: "93vw", // Takes 90% of the viewport width
  maxWidth: "100vw",
  height: "90vh", // Takes 90% of the viewport height
  maxHeight: "100vh",
  overflow: "auto", // Allows scrolling if content exceeds height
  padding: "20px",
  backgroundColor: "#fff", // Adjust as needed
  position: "relative",
  boxShadow: "24px",
  borderRadius: "8px",
};

const tableStyle = {
  width: "100%",
  borderCollapse: "collapse",
  backgroundColor: "#ffffff",
};

const thStyle = {
  padding: "12px",
  textAlign: "left",
  backgroundColor: "#e9ecef",
  color: "#495057",
  fontWeight: "bold",
  textTransform: "uppercase",
  letterSpacing: "0.05em",
  borderBottom: "1px solid #f2f5f4",
};

const tdStyle = {
  padding: "12px",
  textAlign: "left",
  color: "#6c757d",
  backgroundColor: "#fff",
  borderBottom: "1px solid #f2f5f4",
};

const trHover = {
  transition: "background-color 0.3s ease",
};

const fileTagStyle = {
  backgroundColor: "#e6f7e6",
  color: "#027a48",
  fontWeight: "bold",
  padding: "4px 8px",
  borderRadius: "12px",
  display: "inline-block",
};

const fileTypeTagStyle = {
  backgroundColor: "#fff3e6",
  color: "#b54708",
  fontWeight: "bold",
  padding: "4px 8px",
  borderRadius: "12px",
  display: "inline-block",
};

const folderIconStyle = {
  marginRight: "8px",
  verticalAlign: "middle",
  color: "#027a48",
};
const iconStyles = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: "16px",
  paddingBottom: "16px",
  cursor: "pointer",
};

const ChatTableModal = ({ data, isOpen, setIsTableModalOpen, downloadCSV }) => {
  const handleCloseModal = () => {
    setIsTableModalOpen(false);
  };

  if (!Array.isArray(data)) {
    return <div>Error: Data is not in the expected format.</div>;
  }

  if (data.length === 0) {
    return <div>No data available</div>;
  }

  const headers = Object.keys(data[0]);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="100%"
        aria-labelledby="responsive-dialog-title"
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: '#f0f0f0', // Custom background color
            borderRadius: '12px', // Custom border radius
            padding: '20px', // Custom padding inside the dialog
            width: '100%px', // Custom width
            margin:'0px'
          }
        }}
      >
        <div style={modalStyle}>
          <div style={iconStyles}>
            <DownloadIcon onClick={() => downloadCSV(data)} />
            <FullscreenExitIcon onClick={handleCloseModal} />
          </div>

          <table style={tableStyle}>
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th key={index} style={thStyle}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr key={rowIndex} style={trHover}>
                  {headers.map((header, colIndex) => {
                    let content = row[header];
                    let style = tdStyle;

                    if (header === "File") {
                      content = <span style={fileTagStyle}>{row[header]}</span>;
                    } else if (header === "File Type") {
                      content = (
                        <span style={fileTypeTagStyle}>{row[header]}</span>
                      );
                    } else if (header === "Folder" && row[header]) {
                      content = (
                        <>
                          <FolderIcon style={folderIconStyle} />
                          {row[header]}
                        </>
                      );
                    }

                    return (
                      <td key={colIndex} style={style}>
                        {content}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Dialog>
    </div>
  );
};

export default ChatTableModal;
