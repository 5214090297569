import React, { useRef, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './ReportComponent.css'; // Custom CSS file for specific styling
import { MagicWand01 } from '../../SVG';


const ReportComponent = () => {
  const divRef = useRef(null); // Create a ref for the div
  const [expanded, setExpanded] = useState('panel0-name');
  const [activeStep, setActiveStep] = useState(1);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const steps = [1, 2, 3];

  const accordionStyle = {
    width: '400px',
    boxShadow: 'none',
    margin: '0',
  };

  const gridItemStyle = {
    width: '400px',
    boxShadow: 'none',
    margin: '0',
  };


  return (
    <Grid container spacing={2} className="report-container" style={gridItemStyle}>
      <Grid item xs={11} className="content-container">
        {steps.map((step, index) => (
          <div ref={divRef} className="accordianContainer">
            <Accordion
              expanded={expanded === `panel${index}-name`}
              onChange={handleAccordionChange(`panel${index}-name`)}
              style={accordionStyle}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className="section-title">Name</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Mandate for new EV charging infrastructure across Shell's network</Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === `panel${index}-details`}
              onChange={handleAccordionChange(`panel${index}-details`)}
              style={accordionStyle}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className="section-title">Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Mandate for new EV charging infrastructure across Shell's network</Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === `panel${index}-sources`}
              onChange={handleAccordionChange(`panel${index}-sources`)}
              style={accordionStyle}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className="section-title">Sources</Typography>
                <div className="source-count">6</div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="pdfContainer">
                  <p>Operating Manual.pdf</p>
                  <img
                    loading="lazy"
                    alt="pdf"
                    src="/pdf.svg"
                  />
                </div>

                <div className="attachedContainer">
                  <p>Attached Document</p>
                  <p>Page 23 of 128</p>
                </div>
                <img
                  style={{ width: '370px', marginBottom: '5px' }}
                  loading="lazy"
                  alt="detail"
                  src="/detail.png"
                />

                <div className="pdfContainer" style={{ background: '#fff' }}>
                  <p>SOP.pdf</p>
                  <img
                    loading="lazy"
                    alt="pdf"
                    src="/pdf.svg"
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
        <div className="generate-report-btn-container">
          <button className='generate-button'>
            <MagicWand01 />
            Generate Report
          </button>
        </div>
      </Grid>
    </Grid>
  );
};

export default ReportComponent;
