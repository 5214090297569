const parseMarkdownTable = (response) => {
  // Regex to match table rows and columns
  const tablePattern = /\|(.+?)\|/g;

  // Split the response into lines
  const lines = response.split("\n");

  const headers = [];
  const rows = [];

  lines.forEach((line, index) => {
    if (line.match(tablePattern)) {
      // Remove '|' and split the columns
      const columns = line.split("|").filter((col) => col?.trim() !== "");

      if (index === 0) {
        // Header row
        headers.push(...columns);
      } else if (index > 1) {
        // Data rows, assuming the second line is the separator
        rows.push(columns);
      }
    }
  });

  // Convert rows into array of objects with header as keys
  return rows.map((row) => {
    return headers.reduce((obj, header, index) => {
      obj[header?.trim()] = row[index]?.trim();
      return obj;
    }, {});
  });
};
const extractTableFromResponse = (response) => {
  // Split the response into sections by looking for the table start (| character)
  const sections = response.split(/\n\n/); // Split by two newlines to handle paragraphs

  let textPart = "";
  let tablePart = "";

  // Go through each section
  sections.forEach((section) => {
    if (section.includes("|")) {
      // If the section contains a table, assign it to the tablePart
      tablePart = section;
    } else {
      // Otherwise, treat it as text
      textPart += section + "\n\n";
    }
  });

  return { text: textPart.trim(), table: tablePart.trim() };
};
export const formatApiResponse = (response) => {
  // Replace new line characters with <br> for HTML line breaks

  const { text, table } = extractTableFromResponse(response);

  let formattedText = text?.replace(/\n/g, "<br>");
  formattedText = formattedText?.replace(
    /\*\*(.*?)\*\*/g,
    "<strong>$1</strong>"
  );

  // Format the table part
  const tableData = table ? parseMarkdownTable(table) : null;
  console.log(tableData,formattedText,"gpt")

  return { formattedText, tableData };
};
