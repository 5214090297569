import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { Close } from "../../SVG";
import Button from "../../Common/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { ToastNotification } from "../../Utils/ToastNotifications";
import { setCollections } from "../../Redux/reducers/projectsSlice";
import styles from "./styles.module.scss";
import { moveProject, fetchAllCollection } from "../../Api/API";
import CustomSelect from "../../Common/CustomSelect";
import TextArea from "../../Common/TextArea";

const UpdateThreadModal = (props) => {
  const dispatch = useDispatch();
  const { open, setOpen, handleUpdateThread, textAreaValue, setTextAreaValue } =
    props;

  const handleClose = (e) => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={styles.saveContainer}>
          <div className={styles.headingContainer}>
            <h1 className={styles.heading}>Update Thread Name</h1>
            <Close onClick={handleClose} />
          </div>
          <br />
          <TextArea value={textAreaValue} setValue={setTextAreaValue} />
          {/* <p className={styles.para}>
            Move this project to your other collections.
          </p> */}

          <div className={styles.buttonContainer}>
            <Button
              height="40px"
              bgColor="#F3F4F6"
              color="#1E232E"
              radius="100px"
              borderColor="#F3F4F6"
              text="Cancel"
              handleClick={handleClose}
            />
            <Button
              height="40px"
              bgColor="#1F443A"
              color="#fff"
              radius="100px"
              borderColor="#1F443A"
              text="Rename"
              handleClick={handleUpdateThread}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default UpdateThreadModal;
