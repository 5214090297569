import React, { useEffect, useState } from 'react';
import RichTextEditor from "react-rte";
import { useSelector, useDispatch } from 'react-redux';
import styles from "./TextEditor.module.css";
import styles1 from "./Option.module.css";
import Header from "../../../../Components/Header";
import Container from "../../../../Components/Shell/Infra3Tender/Container";
import { setTextEditor, setSelectedText } from '../../../../Components/Redux/reducers/LLM';
import { ToastNotification } from '../../../../Components/Utils/ToastNotifications';
import { textEditorCallForDemo } from '../../../../Components/Api/API';
import { ArrowSquareLeft, EmptyArray, InfoCircle, Subtract1 } from '../../../../Components/SVG';
import { Box, CircularProgress, circularProgressClasses } from '@mui/material';

const TextEditor = () => {
    const { leftOpen, textEditor, selectedText, editorText } = useSelector((state) => state.llm);
    const [editorState, setEditorState] = useState(
        RichTextEditor.createEmptyValue()
    );
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [selectedWords, setSelectedWords] = useState();
    const [selectedCharacters, setSelectedCharacters] = useState();
    // const [selectedText, setSelectedText] = useState("");

    // Update the editor with default text when component mounts


    // Function to capture the user-selected text from the editor
    const handleSelectionChange = () => {
        const currentEditorState = editorState.getEditorState();
        const selectionState = currentEditorState.getSelection();
        const contentState = currentEditorState.getCurrentContent();

        if (!selectionState.isCollapsed()) {
            const startKey = selectionState.getStartKey();
            const startOffset = selectionState.getStartOffset();
            const endKey = selectionState.getEndKey();
            const endOffset = selectionState.getEndOffset();

            let selectedText = contentState
                .getBlockForKey(startKey)
                .getText()
                .slice(startOffset, endOffset);

            if (startKey !== endKey) {
                let block = contentState.getBlockForKey(startKey);
                selectedText = block.getText().slice(startOffset) + "\n";
                let nextBlockKey = contentState.getKeyAfter(startKey);
                while (nextBlockKey && nextBlockKey !== endKey) {
                    block = contentState.getBlockForKey(nextBlockKey);
                    selectedText += block.getText() + "\n";
                    nextBlockKey = contentState.getKeyAfter(nextBlockKey);
                }
                selectedText += contentState
                    .getBlockForKey(endKey)
                    .getText()
                    .slice(0, endOffset);
            }
            dispatch(setSelectedText(selectedText));
        } else {
            dispatch(setSelectedText(""));
        }
    };

    const countWords = (str) => {
        console.log("object, str", str)
        // Trim the string to remove extra spaces and split by space or multiple spaces
        const wordsArray = str?.trim()?.split(/\s+/);

        // Return the length of the wordsArray to get the word count
        return wordsArray?.length;
    }

    const countCharactersWithoutSpaces = (str) => {
        // Remove all spaces from the string and then calculate the length
        const stringWithoutSpaces = str?.replace(/\s/g, '');
        return stringWithoutSpaces?.length;
    }

    const replaceText = (editorState, textToFind, textToReplace) => {
        // Extract current content as markdown (or you can use 'html' or other formats)
        const currentText = editorState.toString("markdown");

        // Replace the text
        const updatedText = currentText.replace(textToFind, textToReplace);

        // Return the updated editor state
        return RichTextEditor.createValueFromString(updatedText, "markdown");
    };

    const handleReplace = (text) => {
        // Replace "Some of it" with "Part of this text"
        const updatedText = replaceText(editorState, selectedText, text);
        setEditorState(updatedText);
    };

    const handleClick = async () => {
        dispatch(setTextEditor([]))
        setLoading(true)
        // Make the POST request
        let body = {
            message: selectedText,
        };

        textEditorCallForDemo(body)
            .then((response) => {
                console.log(response.success, "asdads")

                if (response.success) {

                    console.log(response, "new api response");
                    // const formattedData = formatApiResponse(response?.data?.content);
                    // const cleanedResponse = response?.data?.content?.replace(/\n/g, "");

                    // Now, parse the cleaned response into an array
                    const formattedData = JSON.parse(response?.data?.content);
                    console.log(formattedData, "formattedData");
                    dispatch(setTextEditor(formattedData))
                    setLoading(false)
                } else {
                    console.log(response.success)
                    ToastNotification("info", "Response not fetched ");
                }
            })
            .catch((err) => {
                console.log(err, "error message here");
            });

        // navigate(`/llm-thread`);
    };

    useEffect(() => {
        const initialEditorState = RichTextEditor.createValueFromString(
            editorText,
            "markdown"
        );
        setEditorState(initialEditorState);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        handleSelectionChange();
    }, [editorState]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const wordCount = countWords(selectedText);
        const characterCount = countCharactersWithoutSpaces(selectedText);

        setSelectedWords(selectedText?.length === 0 ? 0 : wordCount)
        setSelectedCharacters(characterCount)

    }, [selectedText]) // eslint-disable-line react-hooks/exhaustive-deps

    const FacebookCircularProgress = (props) => {

        return (
            <Box sx={{ position: 'relative' }}>
                <CircularProgress
                    variant="determinate"
                    sx={{
                        color: "#13D92A", // Conditional color based on theme mode
                    }}
                    size={60}
                    thickness={4}
                    {...props}
                    value={100}
                />
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    sx={{
                        color: '#27AE60',
                        // : '#1a90ff', // Conditional color based on theme mode
                        animationDuration: '900ms',
                        position: 'absolute',
                        left: 0,
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',
                        },
                    }}
                    size={60}
                    thickness={4}
                    {...props}
                />
            </Box>
        );
    };

    return (

        <div className={styles.root}>
            <Header />
            <main className={styles.backgroundParent}>
                <div className={styles.background}>
                    <Container />
                </div>
                <section className={styles.frameWrapper}
                    style={{
                        width: leftOpen ? 'calc(100% - 240px)' : 'calc(100% - 58px)'
                    }}
                >
                    <div className={styles.frameParent}>
                        <div className={styles.editorContainer}>
                            {selectedText?.length > 0 && (
                                <button
                                    className={styles.editorButton}
                                    // onClick={() => dispatch(setTextEditor(selectedText))}
                                    onClick={handleClick}
                                >Expand</button>
                            )}
                            <RichTextEditor
                                value={editorState}
                                onChange={(value) => setEditorState(value)}
                                editorClassName="editor"
                                editorStyle={{
                                    fontFamily: 'sans-serif',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                    height: '100%'
                                }}
                            />
                            <div className={styles.editorCompletion1}>
                                <p>{selectedWords} words {selectedCharacters} characters</p>
                                <div className={styles1.infoBox} style={{ background: '#E5E5E5' }}>
                                    <InfoCircle fill="#1D1D1D" />
                                    <div className={styles1.sourcesText} style={{ color: '#1D1D1D' }}>Sources</div>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: 'calc(43% - 20px)' }}>
                            <div className={styles.editorCompletion}> <p>AI Bid Writer</p></div>
                            {textEditor?.map((item, index) => {
                                console.log('item suggestion,', item.suggestion)

                                const wordCount = countWords(item?.suggestion);
                                const characterCount = countCharactersWithoutSpaces(item?.suggestion);

                                return (
                                    <div className={styles1.container}>
                                        <div className={styles1.header}>
                                            <div className={styles1.title}>Option #{index + 1}</div>
                                            <div className={styles1.infoContainer}>
                                                <button
                                                    className={styles.editorButton}
                                                    style={{ cursor: selectedText?.length > 0 ? 'pointer' : 'not-allowed' }}
                                                    disabled={selectedText?.length === 0 ? true : false}
                                                    onClick={() => handleReplace(item?.suggestion)}
                                                >
                                                    Insert
                                                </button>
                                                <div className={styles1.infoBox}>
                                                    <div className={styles1.infoText}>{wordCount} words {characterCount} characters</div>
                                                </div>
                                                <div className={styles1.infoBox}>
                                                    <InfoCircle fill="#13D92A" />
                                                    <div className={styles1.sourcesText}>Sources</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles1.mainContent}>
                                            <ArrowSquareLeft />
                                            <div className={styles1.textContainer}>
                                                <div className={styles1.textContent}>
                                                    <div className={styles1.textHeader}>
                                                        <div className={styles1.resultBox}>
                                                            <Subtract1 area="14" />
                                                        </div>
                                                        <div className={styles1.resultText}>Results</div>
                                                    </div>
                                                    <div className={styles1.textDescription}>
                                                        <div className={styles1.textParagraph}>
                                                            {item?.suggestion}
                                                            {/* <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: formatApiResponse(item?.suggestion),
                                                                }}
                                                            /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                            {textEditor?.length <= 0 && !loading && (
                                <div className={styles.highlight}>
                                    <EmptyArray />
                                    <p>Highlight some text and select a function to get started!</p>
                                </div>
                            )}
                            {textEditor?.length <= 0 && loading && (
                                <div className={styles.highlight}>
                                    <FacebookCircularProgress />
                                    <p>Please hold on, your data is on the way!</p>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
};

export default TextEditor;
