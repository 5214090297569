import { createSlice } from "@reduxjs/toolkit";

const substationSlice = createSlice({
  name: "substations",
  initialState: {
    userLocation: null,
    isFindClosestPoints: false,
    substations: [],
    privateWire: [],
    secondaryAcquisitions: [],
    planningData: {},
    landOwnerData: [],
    habitatData: [],
    clossestPlanningProjects: [],
    // popupSubStation: null,
    maplayerFilter: false,
    leftSidebarSelectedOption: 'search',
    centroid: null,
    showSchematic: false,
    showDistributionSchematic: false,
    showTransmissionStep: false,
    showDistributionConstraints: false,
  },
  reducers: {
    setUserLocation: (state, action) => {
      return { ...state, userLocation: action.payload };
    },
    triggerClosestPoints: (state, action) => {
      return { ...state, isFindClosestPoints: action.payload };
    },
    setSubstations: (state, action) => {
      return { ...state, substations: action.payload };
    },
    setPlanningData: (state, action) => {
      return { ...state, planningData: action.payload };
    },
    setClosestPlanningProjects: (state, action) => {
      return { ...state, clossestPlanningProjects: action.payload };
    },
    // setPopupSubStation: (state, action) => {
    //   return {
    //     ...state,
    //     popupSubStation: action.payload
    //   }
    // },
    setMaplayerFilter: (state, action) => {
      return { ...state, maplayerFilter: action.payload };
    },
    setleftSidebarSelectedOption: (state, action) => {
      return { ...state, leftSidebarSelectedOption: action.payload };
    },
    setPolygonCentroid: (state, action) => {
      return { ...state, centroid: action.payload };
    }, 
    setLandOwnerData: (state, action) => {
      return { ...state, landOwnerData: action.payload };
    },
    setHabitatData: (state, action) => {
      return { ...state, habitatData: action.payload };
    },
    setShowSchemantic: (state, action) => {
      return { ...state, showSchematic: action.payload };
    },
    setShowDistributionSchematic: (state, action) => {
      return { ...state, showDistributionSchematic: action.payload };
    },
    setShowTransmissionStep: (state, action) => {
      return { ...state, showTransmissionStep: action.payload };
    },
    setShowDistributionConstraints: (state, action) => {
      return { ...state, showDistributionConstraints: action.payload };
    },
    setPrivateWire: (state, action) => {
      return { ...state, privateWire: action.payload };
    },   
    setSecondaryAcquisition: (state, action) => {
      return { ...state, secondaryAcquisitions: action.payload };
    },
  },
});

export const {
  setUserLocation,
  triggerClosestPoints,
  setSubstations,
  setPlanningData,
  setClosestPlanningProjects,
  // setPopupSubStation,
  setMaplayerFilter,
  setleftSidebarSelectedOption,
  setPolygonCentroid,
  setLandOwnerData,
  setHabitatData,
  setShowSchemantic,
  setShowDistributionSchematic,
  setShowTransmissionStep,
  setShowDistributionConstraints,
  setPrivateWire,
  setSecondaryAcquisition
} = substationSlice.actions;
export default substationSlice.reducer;
