import { Button } from "@mui/material";
import PropTypes from "prop-types";
import styles from "./OrgSelectorContainer.module.css";
import { useNavigate } from "react-router-dom";
import { ColorChat, Plus } from "../../SVG";

const OrgSelectorContainer = ({ className = "" }) => {
  const navigate = useNavigate();
  return (
    <header className={[styles.orgSelectorContainer, className].join(" ")}>
      <div className={styles.container1}>
        <div className={styles.newThreadContainer}>
          <div className={styles.newThreadWrapper}>
            <ColorChat />
            <div className={styles.threadNameContainer}>
              <div className={styles.threadNameWrapper}>
                <a className={styles.threads}>Threads</a>
              </div>
            </div>
          </div>
        </div>
        <Button
          className={styles.buttonDialog}
          startIcon={<Plus fill="#151616" />}
          disableElevation
          variant="contained"
          sx={{
            textTransform: "none",
            color: "#0c2721",
            fontSize: "14",
            background: "#13d92a",
            borderRadius: "8px",
            "&:hover": { background: "#13d92a" },
            width: 140,
            height: 40,
          }}
          onClick={() => navigate("/llm-tender")}
        >
          New thread
        </Button>
      </div>
    </header>
  );
};

OrgSelectorContainer.propTypes = {
  className: PropTypes.string,
};

export default OrgSelectorContainer;
