import './ChatLoader.css'
const ChatLoader = () => {
    return (

        <div>
            <div className="css-1166osl">&nbsp;</div>
            <div className="css-1166osl">&nbsp;</div>
            <div className="css-1166osl">&nbsp;</div>
            {/* <div className="css-1166osl">&nbsp;</div> */}
        </div>
    );
};

export default ChatLoader