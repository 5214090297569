import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import styles from "./FrameComponent1.module.css";
import { useState } from "react";
import { MagicWand01New, SearchIcon, Subtract1 } from "../../SVG";
import { setEditorText } from "../../Redux/reducers/LLM";
import { useNavigate } from "react-router-dom";
import ChatLoader from "../Infra3/ChatLoader";

const FrameComponent1 = ({ className = "" }) => {
  const { threads, activeThread, loading } = useSelector((state) => state.llm);
  const [selectedItem, setSelectedItem] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleShowSteps = (index) => {
    console.log(selectedItem, "asasas", index);
    if (selectedItem === index) {
      setSelectedItem("");
    } else {
      setSelectedItem(index);
    }
  };

  const handleBidWriterClick = (text) => {
    dispatch(setEditorText(text))
    navigate("/llm-tender-textEditor");
  }

  const formatApiResponse = (response) => {
    // Replace new line characters with <br> for HTML line breaks
    let formattedResponse = response?.replace(/\n/g, "<br>");

    // Replace markdown bold (**text**) with HTML bold (<strong>text</strong>)
    formattedResponse = formattedResponse?.replace(
      /\*\*(.*?)\*\*/g,
      "<strong>$1</strong>"
    );

    return formattedResponse;
  };
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1500);
  // }, []);

  return (
    <div className={[styles.frameParent11, className].join(" ")}>
      <div style={{ width: "768px" }}>
        {activeThread?.[0]?.content?.map((item, index, array) => {
          const isLastMessage = index === array.length - 1;
          const lastMessage =
            activeThread?.[0]?.content?.[activeThread[0].content.length - 1];
          console.log(lastMessage, "last message");
          return (
            <>
              {item.user === "user" && (
                <div className={styles.documentContainerWrapper}>
                  <div className={styles.documentContainer}>
                    <div className={styles.documentHeader}>
                      <div className={styles.documentTitle}>
                        <h3 className={styles.vq}>VQ</h3>
                      </div>
                      <h1 className={styles.checkTheseThree}>{item.message}</h1>
                    </div>
                    <div className={styles.filesParent}>
                      <div className={styles.frameWrapper}>
                        <div className={styles.frameChild} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {item.user === "assistant" && (
                <>
                  <div className={styles.resultsContainerParent}>
                    <div className={styles.resultsContainer}>
                      <div className={styles.resultsHeader}>
                        <div className={styles.overlaybordershadow}>
                          <Subtract1 area="14" />
                        </div>
                        <div className={styles.resultsWrapper}>
                          <h1 className={styles.results}>Results</h1>
                        </div>
                      </div>
                      <div className={styles.resultsContainer1}>
                        <MagicWand01New />
                        <div
                          className={styles.resultsWrapper}
                          onClick={() => handleShowSteps(index)}
                        >
                          <h2 className={styles.showSteps}>
                            {" "}
                            {selectedItem === index
                              ? "Hide steps"
                              : "Show steps"}
                          </h2>
                        </div>
                      </div>
                    </div>
                    {selectedItem === index && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#f9f9f9",
                          borderRadius: "8px",
                          padding: "10px",
                          width: "100%",
                          maxWidth: "685px",
                          border: "1px solid #e0e0e0",
                          marginLeft: "46px",
                          // justifyContent:'flex-end',
                          gap: 1,
                        }}
                      >
                        <SearchIcon
                          sx={{ marginRight: "25px", color: "#616161" }}
                        />
                        <Typography variant="body1" color="textPrimary">
                          Searching examples and definitions
                        </Typography>
                      </Box>
                    )}

                    <div className={styles.queryContainer}>
                      <h1 className={styles.iSeeThat}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: formatApiResponse(item?.message),
                          }}
                        />
                      </h1>
                    </div>
                  </div>
                </>
              )}
              {item.user === "file" && (
                <>
                  <div className={styles.resultsContainerParent} style={{ marginTop: '20px' }}>
                    <div className={styles.resultsContainer}>
                      <div className={styles.resultsHeader}>
                        <div className={styles.overlaybordershadow}>
                          <Subtract1 area="14" />
                        </div>
                        <div className={styles.resultsWrapper}>
                          <h1 className={styles.results}>Results</h1>
                        </div>
                      </div>
                    </div>

                    <div className={styles.queryContainer}>
                      <h1 className={styles.iSeeThat}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: formatApiResponse(item?.message),
                          }}
                        />
                      </h1>
                    </div>
                  </div>
                  <button className={styles.bidWriter} onClick={() => handleBidWriterClick(item?.message)}>
                    <img
                      className={styles.microphoneIcon}
                      loading="lazy"
                      alt=""
                      src="/frame-9@2x.png"
                      style={{ width: '20px' }}
                    />
                    Go to AI Bid Writer
                  </button>
                </>
              )}
              {isLastMessage && lastMessage.user == "user" && (
                <div className={styles.loadingState}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#f9f9f9",
                      borderRadius: "8px",
                      padding: "10px",
                      width: "100%",
                      maxWidth: "685px",
                      border: "1px solid #e0e0e0",
                      gap: 1,
                      marginBottom: "10px",
                    }}
                  >
                    <SearchIcon
                      sx={{ marginRight: "15px", color: "#616161" }}
                    />
                    <Typography variant="body1" color="textPrimary">
                      Searching examples and definitions
                    </Typography>
                  </Box>
                  <div className={styles.pulseContainer}>
                    <ChatLoader />
                    {/* <div className={styles.pulseBar}></div>
                    <div className={styles.pulseBar}></div>
                    <div className={styles.pulseBar}></div>                     */}
                  </div>
                </div>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
