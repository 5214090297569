import styles from "./Home.module.css";
import Header from "../../../../Components/Header";
import Container from "../../../../Components/Shell/Infra3Tender/Container";
import Container2 from "../../../../Components/Shell/Infra3Tender/Container2";
import { useSelector } from "react-redux";
import { Subtract1 } from "../../../../Components/SVG";

const Home = () => {
  const { leftOpen } = useSelector((state) => state.llm);

  return (
    <div className={styles.root}>
      <Header />
      <main className={styles.backgroundParent}>
        <div className={styles.background}>
          <Container />
        </div>
        <section
          className={styles.frameWrapper}
          style={{
            width: leftOpen ? "calc(100% - 240px)" : "calc(100% - 58px)",
          }}
        >
          <div className={styles.frameParent}>
            <div className={styles.containerWrapper}>
              <div className={styles.container9}>
                <div className={styles.overlaybordershadowWrapper}>
                  <div className={styles.overlaybordershadow2}>
                    <Subtract1 area="34" />
                  </div>
                </div>
                <div className={styles.margin1}>
                  <div className={styles.container10}>
                    <div className={styles.heading2}>
                      <h1 className={styles.talkDataTo}>
                        Talk tendering to me
                      </h1>
                    </div>
                    <div className={styles.container11}>
                      <div className={styles.div}>
                        <p className={styles.inThisDemo}>
                          In this demo you have access to a dummy
                        </p>
                        <p className={styles.inThisDemo}>
                          Please upload tendering or bid documents here and i
                          will summerise them
                        </p>
                        <p className={styles.inThisDemo}>
                          request to get started.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <footer className={styles.container12}>
              <div className={styles.container13}>
                <div className={styles.frameGroup}>
                  <Container2 />
                </div>
              </div>
            </footer>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Home;
