import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Geocode from "react-geocode";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SearchBar from "./../../Common/SearchBar/SearchBar";
import RangeSlider from "./../../Common/RangeSlider/RangeSlider";
import QuickSelectors from "./QuickSelectors";
import RadioSelectors from "./RadioSelectors";
import Assets from "./Assets";
import Filters from "./Filters";
import styles from "./styles.module.scss";
import FilterButton from "./FilterButton";
import AppTextBox from "../../Common/AppTextBox/AppTextBox";
import InputSelect from "../../Common/InputSelect";
import LayersModal from "../../Common/MapFilterCard/LayersModal";
import { ToastNotification } from "../../Utils/ToastNotifications";
import {
  setSearchValue,
  setFilterValues,
  setMapLoader,
  setSearchTypeAction,
  setDrawnPolygon,
  setDrawnLine,
  setCurrentCenter
} from "../../Redux/reducers/filterSlice";
import {
  setSubstations,
  setUserLocation,
  triggerClosestPoints,
  setPlanningData,
  setClosestPlanningProjects,
  setMaplayerFilter,
  setleftSidebarSelectedOption,
  setPolygonCentroid,
} from "../../Redux/reducers/substationSlice";
import {
  appendNewLayer,
  removeLayer,
  resetLayersData,
} from "../../Redux/reducers/datasetSlice";
import {
  setRightSideBarOpen,
  setLeftSideBarOpen,
} from "../../Redux/reducers/projectsSlice";
import { addSearch, getCoordinates } from "../../Api/API";
import { setSelectedSubstation, setRoute, setAllowSecSubs } from "../../Redux/reducers/selectedSubstation";
import { AiIcon, BurgerIcon, KeyIcon, Layers, SpyIcon } from "../../SVG";
import SpyMode from "./SpyMode";
import GptOsmMode from "./GptOsmMode";
import SymbolicIcons from "./SymbolicIcons";
import LayerSideBar from "./LayerSideBar";

const LeftSidebar = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [demandChecked, setDemandChecked] = useState(true);
  // const [maplayerFilter, setMaplayerFilter] = useState();

  const [generationChecked, setGenerationChecked] = useState(false);
  const [buttonText, setButtonText] = useState("Real Estate");
  const [filterButtonText, setfilterButtonText] = useState("");
  // const [filters, updatefilters] = useState(["Risk", "IQ"]);
  const [riskScore, setRiskScore] = useState(true);
  const [iqScore, setIqScore] = useState(true);
  const [autoLayers, setAutoLayers] = useState(true);

  const [radius, setRadius] = useState([0, 50]);
  const [selectedRadius, setSelectedRadius] = useState([0, 50]);
  const [radiusUnit, setRadiusUnit] = useState("Miles");
  const [inputCapacity, setInputCapacity] = useState(null);
  const [projectCapacity, setProjectCapacity] = useState([0.001, 1000]);
  const [searchType, setSearchType] = useState("Address");
  const [selectedUnit, setSelectedUnit] = useState("MW");
  const [showAllSubstations, setShowAllSubstations] = useState("true");
  const filter = useSelector((state) => state.filters);
  const substations = useSelector((state) => state.substations);
  const projects = useSelector((state) => state.projects);
  const osmData = useSelector((state) => state.gptOsmData.osmData);

  const [searchValueTemp, setSearchValueTemp] = useState(
    filter.searchValue || ""
  );
  const centroid = useSelector((state) => state.substations.centroid);
  const datasets = useSelector((state) => state.dataset);
  console.log(datasets, "datasets");

  const { userDetail } = useSelector((state) => state.users);

  const leftSidebarSelectedOption = useSelector(
    (state) => state.substations.leftSidebarSelectedOption
  );
  const GOOGLE_MAP_API_KEY = "AIzaSyCQFqXwp4QZGkJuCQr6SjPwiTr8QHckksg";
  Geocode.setApiKey(GOOGLE_MAP_API_KEY);

  const assetsButtonsArray = [
    { id: 1, text: "Real Estate" },
    { id: 2, text: "Renewable Energy" },
    { id: 3, text: "EV Infrastructure" },
    { id: 4, text: "Energy Storage" },
  ];

  const filterButtonsArray = [
    { id: 1, text: "Reset" },
    { id: 2, text: "Apply" },
  ];

  const handleAssetType = (text) => {
    setButtonText(text);
    if (text === "Real Estate" || text === "EV Infrastructure") {
      setDemandChecked(true);
      setGenerationChecked(false);
    }
    if (text === "Renewable Energy") {
      setGenerationChecked(true);
      setDemandChecked(false);
    }
    if (text === "Energy Storage") {
      setGenerationChecked(true);
      setDemandChecked(true);
    }
  };

  const handleLocation = (value) => {
    setSearchValueTemp(value);
  };
  const convertToMW = (value) => {
    return convertCapacity(value, selectedUnit);
  };

  const extractCoordinates = (input) => {
    // Use a regular expression to match numeric values
    const matches = input.match(/-?\d+\.\d+/g);

    if (matches && matches.length >= 2) {
      // Extract the first two matches (latitude and longitude)
      const latitude = matches[0];
      const longitude = matches[1];

      // Format the result
      const result = `${latitude}, ${longitude}`;

      return result;
    }

    // Handle invalid input or insufficient matches
    return "Invalid input";
  };

  const mapIcons = {
    "Real Estate":
      "/icons/realestate_search.png",
    "Renewable Energy":
      "/icons/renewable_search.png",
    "EV Infrastructure":
      "/icons/ev_search.png",
    "Energy Storage":
      "/icons/battery_search.png",
  };

  const handleFiltersButtonClick = async (text) => {
    dispatch(setRightSideBarOpen(false));
    dispatch(setAllowSecSubs(false));
    dispatch(setSubstations([]));
    // const { searchValueTemp } = filter;
    // Convert project capacity values to MW if the selected unit is KW
    const minCapacity =
      selectedUnit === "kW" ? convertToMW(inputCapacity) : inputCapacity;
    if (text === "Apply") {
      if (
        searchType !== "Draw Polygon" &&
        (!searchValueTemp || searchValueTemp === null || searchValueTemp === "")
      ) {
        ToastNotification("warn", "Location is required");
        return;
      } else if (searchType === "Draw Polygon" && !centroid) {
        ToastNotification("warn", "Polygon required");
        dispatch(setMapLoader(false));
        return;
      } else if (!demandChecked && !generationChecked) {
        ToastNotification("warn", "At least 1 quick selector is required");
        return;
      } else if (!inputCapacity || inputCapacity === null) {
        ToastNotification("warn", "Project capacity is required");
        return;
      } else if (
        inputCapacity < projectCapacity[0] ||
        inputCapacity > projectCapacity[1]
      ) {
        ToastNotification(
          "warn",
          `Project capacity is required between ${projectCapacity[0]} to ${projectCapacity[1]}`
        );
        return;
      } else {
        dispatch(setMapLoader(true));
        // searchValue.replace(/[a-zA-Z]/g, '');
        // const value = searchType === "Address" ? `${searchValue} uk` : searchType === "Coordinates" ? extractCoordinates(searchValue) : await findCoordinate(searchValue)
        let value = "";
        if (searchType === "Address") {
          value = `${searchValueTemp} uk`;
        } else if (searchType === "Coordinates") {
          value = extractCoordinates(searchValueTemp);
        } else {
          const body = {
            name: searchValueTemp,
          };
          const result = await getCoordinates(body)
            .then((response) => {
              if (response.success) {
                return response?.data;
              }
            })
            .catch((err) => {
              console.log("coordinate error", err);
            });
          value = `${result?.lat}, ${result?.lng}`;
        }

        // Convert project capacity values to MW if the selected unit is KW
        const minCapacity =
          selectedUnit === "kW" ? convertToMW(inputCapacity) : inputCapacity;
        // Geocode.fromAddress(`${searchValue} uk`).then(
        if (searchType !== "Draw Polygon") {
          Geocode.fromAddress(value).then(
            (response) => {
              if (response?.results[0].formatted_address === "United Kingdom") {
                console.error("Error, Incorrect location");
                ToastNotification("error", "Search location not found");
                dispatch(setMapLoader(false));
              } else {
                console.log("response: ", response);
                // dispatch(setMapLoader(false));
                // dispatch(setRightSideBarOpen(true));
                dispatch(setLeftSideBarOpen(false));
                dispatch(setDrawnPolygon(null));
                dispatch(setPolygonCentroid(null));
                const { lat, lng } = response.results[0].geometry.location;
                // dispatch(setUserLocation({ lat: lat, lng: lng }));
                dispatch(setSelectedSubstation(null));
                dispatch(setRoute({}));
                // Convert project capacity values to MW if the selected unit is KW
                const minCapacity =
                  selectedUnit === "kW"
                    ? convertToMW(inputCapacity)
                    : inputCapacity;
                // const maxCapacity = inputCapacity;
                // const maxCapacity =
                //   selectedUnit === "kW"
                //     ? convertToMW(projectCapacity[1])
                //     : projectCapacity[1];
                // console.log("min", minCapacity, "max", maxCapacity);
                dispatch(setSearchValue(searchValueTemp));
                dispatch(
                  setFilterValues({
                    minRadius: selectedRadius?.[0],
                    maxRadius: selectedRadius?.[1],
                    minCapacity: `${minCapacity}`,
                    // maxCapacity: maxCapacity,
                    demand: demandChecked,
                    generation: generationChecked,
                    riskAndIQ: [riskScore && "Risk", iqScore && "IQ"],
                    // riskAndIQ: filters,
                    assetType: buttonText,
                    assetTypeUrl: mapIcons[buttonText],
                    radiusUnit: radiusUnit,
                    capacityUnit: selectedUnit,
                    showAllSubstations: showAllSubstations,
                    enableLayers: autoLayers,
                    search_by: searchType,
                  })
                );

                if (searchType === "Draw Polygon") {
                  dispatch(
                    setUserLocation({ lat: centroid.lat, lng: centroid.lng })
                  );
                } else {
                  dispatch(setUserLocation({ lat: lat, lng: lng }));
                }

                dispatch(triggerClosestPoints(true));
                if (
                  (buttonText === "Real Estate" ||
                    buttonText === "Renewable Energy" ||
                    buttonText === "Energy Storage") &&
                  autoLayers
                ) {
                  dispatch(removeLayer({ layerName: "ncpr" }));
                  // dispatch(
                  //   appendNewLayer({ layerName: "listed-building-outline" })
                  // );
                  // dispatch(
                  //   appendNewLayer({
                  //     layerName: "agriculture-land-classification2",
                  //   })
                  // );
                }
                if (buttonText === "EV Infrastructure" && autoLayers) {
                  // dispatch(
                  //   appendNewLayer({ layerName: "green_belt", append: false })
                  // );
                  dispatch(
                    appendNewLayer({ layerName: "ncpr", append: false })
                  );
                }
              }
            },
            (error) => {
              console.error("Error geocoding location:", error);
              ToastNotification("error", "Search location not found");
              dispatch(setMapLoader(false));
            }
          );
        } else {
          // console.log("response: ", response);
          // dispatch(setMapLoader(false));
          // dispatch(setRightSideBarOpen(true));
          dispatch(setLeftSideBarOpen(false));
          // const { lat, lng } = response.results[0].geometry.location;
          // dispatch(setUserLocation({ lat: lat, lng: lng }));
          dispatch(setSelectedSubstation(null));
          dispatch(setRoute({}));
          // const maxCapacity = inputCapacity;
          // const maxCapacity =
          //   selectedUnit === "kW"
          //     ? convertToMW(projectCapacity[1])
          //     : projectCapacity[1];
          // console.log("min", minCapacity, "max", maxCapacity);
          dispatch(setSearchValue(searchValueTemp));
          dispatch(
            setFilterValues({
              minRadius: selectedRadius?.[0],
              maxRadius: selectedRadius?.[1],
              minCapacity: `${minCapacity}`,
              // maxCapacity: maxCapacity,
              demand: demandChecked,
              generation: generationChecked,
              riskAndIQ: [riskScore && "Risk", iqScore && "IQ"],
              // riskAndIQ: filters,
              assetType: buttonText,
              assetTypeUrl: mapIcons[buttonText],
              radiusUnit: radiusUnit,
              capacityUnit: selectedUnit,
              showAllSubstations: showAllSubstations,
              enableLayers: autoLayers,
              search_by: searchType,
            })
          );

          if (searchType === "Draw Polygon") {
            dispatch(setUserLocation({ lat: centroid.lat, lng: centroid.lng }));
          } else {
            // dispatch(setUserLocation({ lat: lat, lng: lng }));
          }

          dispatch(triggerClosestPoints(true));
          if (
            (buttonText === "Real Estate" ||
              buttonText === "Renewable Energy" ||
              buttonText === "Energy Storage") &&
            autoLayers
          ) {
            dispatch(removeLayer({ layerName: "ncpr" }));
            // dispatch(
            //   appendNewLayer({ layerName: "listed-building-outline" })
            // );
            // dispatch(
            //   appendNewLayer({
            //     layerName: "agriculture-land-classification2",
            //   })
            // );
          }
          if (buttonText === "EV Infrastructure" && autoLayers) {
            // dispatch(
            //   appendNewLayer({ layerName: "green_belt", append: false })
            // );
            dispatch(appendNewLayer({ layerName: "ncpr", append: false }));
          }
        }

        // API Call to save result in QuickSight Dashboard
        let body = {
          search_by: searchType,
          location: searchValueTemp,
          distance_in: radiusUnit,
          assetType: buttonText,
          capacityUnit: selectedUnit,
          capacity: minCapacity,
          minRadius: selectedRadius?.[0],
          maxRadius: selectedRadius?.[1],
        };
        addSearch(body)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => console.error(err));
      }
      // API Call to save result in QuickSight Dashboard
      let body = {
        search_by: searchType,
        location: searchValueTemp,
        distance_in: radiusUnit,
        assetType: buttonText,
        capacityUnit: selectedUnit,
        capacity: minCapacity,
        minRadius: selectedRadius?.[0],
        maxRadius: selectedRadius?.[1],
      };
      addSearch(body)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => console.error(err));
    }

    if (text === "Reset") {
      dispatch(setSearchValue(""));
      setSearchValueTemp("");
      setInputCapacity("");
      dispatch(
        setFilterValues({
          minRadius: "",
          maxRadius: "",
          minCapacity: "",
          maxCapacity: "",
          demand: true,
          generation: false,
          enableLayers: "true",
        })
      );
      dispatch(resetLayersData());
      dispatch(setUserLocation(null));
      dispatch(setSubstations([]));
      dispatch(setPlanningData({}));
      dispatch(setClosestPlanningProjects([]));
      setButtonText("Real Estate");
      setDemandChecked(true);
      setRadius([0, 20]);
      setSelectedRadius([0, 20]);
      setProjectCapacity([0.001, 1000]);
      dispatch(setSelectedSubstation(null));
      dispatch(setRoute({}));
      dispatch(setPolygonCentroid(null));
      dispatch(setDrawnPolygon(null));
      dispatch(setDrawnLine(null));
      dispatch(setCurrentCenter(null));
    }

    setfilterButtonText(text);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleFiltersButtonClick("Apply");
    }
  };

  const handleDemandChange = (value) => {
    setDemandChecked(!demandChecked);
  };

  const handleGenerationChange = (value) => {
    setGenerationChecked(!generationChecked);
  };
  const handleShowAllSubstations = (e) => {
    setShowAllSubstations(e.target.value);
  };

  const convertCapacity = (value, unit) => {
    if (unit === "kW") {
      return value / 1000; // Convert KW to MW
    } else {
      return value * 1000; // Convert MW to KW
    }
  };
  // const convertCapacity = (value, conversionType) => {
  //   const numericValue = parseFloat(value);

  //   if (isNaN(numericValue)) {
  //     console.error("Invalid input. Please enter a valid number.");
  //     return null;
  //   }

  //   if (conversionType === "mwToKw") {
  //     return numericValue / 1000;
  //   } else if (conversionType === "kwToMw") {
  //     return numericValue * 1000;
  //   } else {
  //     console.error('Invalid conversion type. Use "mwToKw" or "kwToMw".');
  //     return null;
  //   }
  // };

  const updateSliderUnit = (event) => {
    const selectedValue = event.target.value;
    setSelectedUnit(selectedValue);

    // Define the new min and max values based on the selected unit
    const newMinValue = selectedValue === "MW" ? 0.001 : 1;
    const newMaxValue = selectedValue === "MW" ? 1000 : 1000000;
    // Update the projectCapacity state array with the new min and max values
    setProjectCapacity([newMinValue, newMaxValue]);
  };

  const updateRadius = (event) => {
    const selectedValue = event.target.value;
    setRadiusUnit(selectedValue);

    // Define the new min and max values based on the selected unit
    const newValue =
      selectedValue === "Kilometers"
        ? radius?.[1] * 1.60934
        : radius?.[1] / 1.60934;

    setRadius([0, newValue]);
    setSelectedRadius([0, newValue]);
  };
  const setOpenLayersModal = () => {
    dispatch(setMaplayerFilter(!substations?.maplayerFilter));
    // dispatch(setleftSidebarSelectedOption('search'))
  };
  const handleSpyModeClick = () => {
    dispatch(setLeftSideBarOpen(true));
    dispatch(setleftSidebarSelectedOption("technologylegend"));
    dispatch(appendNewLayer({ layerName: "tidal-lagoon-renewable" }));
  };
  useEffect(() => {
    if (leftSidebarSelectedOption !== "technologylegend") {
      dispatch(removeLayer({ layerName: "tidal-lagoon-renewable" }));
    }
  }, [leftSidebarSelectedOption]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className={styles.main1_container}>
      <div className={styles.icon_container}>
        <NavLink
          to="/"
          className={
            location.pathname === "/" &&
              substations?.maplayerFilter === false &&
              leftSidebarSelectedOption === "search"
              ? styles.active
              : styles.icons
          }
          onClick={() => {
            dispatch(setLeftSideBarOpen(!projects.leftSidebarOpen));
            dispatch(setleftSidebarSelectedOption("search"));
          }}
        >
          <BurgerIcon
            fill={
              location.pathname === "/" &&
                substations?.maplayerFilter === false &&
                leftSidebarSelectedOption === "search"
                ? "#349879"
                : "#1F443A"
            }
          />
        </NavLink>
        {/* <NavLink
          to="/projects"
          className={
            location.pathname === "/projects" ? styles.active : styles.icons
          }
        >
          <ElectricBoltIcon
            fill={location.pathname === "/projects" ? "#1F443A" : "#1F443A"}
          />
        </NavLink> */}
        {location.pathname === "/" && (
          <>
            <NavLink
              className={
                location.pathname === "/" &&
                  // substations?.maplayerFilter === true &&
                  leftSidebarSelectedOption === "layers"
                  ? styles.active
                  : styles.icons
              }
              // onClick={setOpenLayersModal}
              onClick={() => {
                dispatch(setLeftSideBarOpen(true));
                dispatch(setleftSidebarSelectedOption("layers"));
                // dispatch(setMaplayerFilter(!substations?.maplayerFilter));
              }}
            >
              <Layers
                fill={
                  location.pathname === "/" &&
                    // substations?.maplayerFilter === true &&
                    leftSidebarSelectedOption === "layers"
                    ? "#349879"
                    : "#1F443A"
                }
              />
              {datasets?.activeCard?.length > 0 && (
                <div
                  style={{
                    height: "15px",
                    width: "15px",
                    borderRadius: "50px",
                    background: "#1F443A",
                    marginBottom: "10px",
                    color: "white",
                    fontSize: "11px",
                    textAlign: "center",
                    padding: '1px'
                  }}
                >
                  {datasets?.activeCard?.length}
                </div>
              )}
            </NavLink>
            <NavLink
              className={
                location.pathname === "/" &&
                  substations?.maplayerFilter === false &&
                  leftSidebarSelectedOption === "vqinfralegend"
                  ? styles.active
                  : styles.icons
              }
              onClick={() => {
                dispatch(setLeftSideBarOpen(true));
                dispatch(setleftSidebarSelectedOption("vqinfralegend"));
              }}
            >
              <KeyIcon
                fill={
                  location.pathname === "/" &&
                    substations?.maplayerFilter === false &&
                    leftSidebarSelectedOption === "vqinfralegend"
                    ? "#349879"
                    : "#1F443A"
                }
              />
            </NavLink>
            {(userDetail?.user?.subscription === "Silver" ||
              userDetail?.user?.subscription === "Gold" ||
              userDetail?.user?.subscription === "Enterprise") && (
                <NavLink
                  className={
                    location.pathname === "/" &&
                      substations?.maplayerFilter === false &&
                      leftSidebarSelectedOption === "technologylegend"
                      ? styles.active
                      : styles.icons
                  }
                  onClick={handleSpyModeClick}
                >
                  <SpyIcon
                    fill={
                      location.pathname === "/" &&
                        substations?.maplayerFilter === false &&
                        leftSidebarSelectedOption === "technologylegend"
                        ? "#349879"
                        : "#1F443A"
                    }
                  />
                  {datasets?.activeRenewableLayers?.length > 0 && (
                    <div
                      style={{
                        height: "15px",
                        width: "15px",
                        borderRadius: "50px",
                        background: "#1F443A",
                        marginBottom: "10px",
                        color: "white",
                        fontSize: "11px",
                        textAlign: "center",
                        padding: '1px'
                      }}
                    >
                      {datasets?.activeRenewableLayers?.length}
                    </div>
                  )}
                </NavLink>
              )}
            {(userDetail?.user?.subscription === "Gold" ||
              userDetail?.user?.subscription === "Enterprise") && (
                <NavLink
                  className={
                    location.pathname === "/" &&
                      substations?.maplayerFilter === false &&
                      leftSidebarSelectedOption === "chatgptosm"
                      ? styles.active
                      : styles.icons
                  }
                  onClick={() => {
                    dispatch(setleftSidebarSelectedOption("chatgptosm"));
                    dispatch(setLeftSideBarOpen(true));
                  }}
                >
                  <AiIcon
                    fill={
                      location.pathname === "/" &&
                        substations?.maplayerFilter === false &&
                        leftSidebarSelectedOption === "chatgptosm"
                        ? "#349879"
                        : "#1F443A"
                    }
                  />
                  {osmData?.length > 0 && (
                    <div
                      style={{
                        height: "15px",
                        width: "15px",
                        borderRadius: "50px",
                        background: "#1F443A",
                        marginBottom: "10px",
                        color: "white",
                        fontSize: "11px",
                        textAlign: "center",
                        padding: '1px',
                        marginLeft: '2px'
                      }}
                    >
                      {osmData.length}
                    </div>
                  )}
                </NavLink>
              )}
          </>
        )}
      </div>
      <div
        className={
          projects?.leftSidebarOpen
            ? styles.left_sidebar_container
            : styles.close_leftbar
        }
        style={{paddingBottom: leftSidebarSelectedOption === "layers" ? "0px" : '30px'}}
      >
        {leftSidebarSelectedOption === "search" && (
          <div className={styles.search_bar}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h1 style={{ marginRight: "0px" }}>Filters</h1>
              <div
                className={styles.power_select}
                style={{ display: "flex", alignItems: "center" }}
              >
                <InputSelect
                  width="140px"
                  title="Search by"
                  value={searchType}
                  handler={(e) => {
                    setSearchType(e.target.value);
                    dispatch(setSearchTypeAction(e.target.value));
                  }}
                  option={[
                    "Address",
                    "Coordinates",
                    "What3Words",
                    "Draw Polygon",
                  ]}
                />
              </div>
            </div>
            <SearchBar
              searchType={searchType}
              handleLocation={handleLocation}
              searchValue={searchValueTemp}
              onKeyPress={handleKeyPress}
              placeholder={
                searchType === "Address"
                  ? "Location e.g. NW1 2AE"
                  : searchType === "Coordinates"
                    ? "Location e.g. 51.507, -0.127"
                    : searchType === "What3Words"
                      ? "Location e.g. ///smart.grid.into"
                      : searchType === "Draw Polygon" && !centroid
                        ? "Draw Polygon"
                        : "Draw Polygon"
              }
            />
            <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h1 style={{ marginRight: "0px" }}>Radius</h1>
              <div
                className={styles.power_select}
                style={{ display: "flex", alignItems: "center" }}
              >
                <InputSelect
                  width="130px"
                  title="Distance"
                  value={radiusUnit}
                  handler={updateRadius}
                  option={["Miles", "Kilometers"]}
                />
              </div>
            </div>
            <RangeSlider
              value={selectedRadius}
              setValue={(value) => setSelectedRadius(value)}
              unit={radiusUnit === "Miles" ? radiusUnit : "km"}
              minValue={radius?.[0]}
              maxValue={radius?.[1]}
            />
            <Assets
              assetsButtonsArray={assetsButtonsArray}
              handleButtonClick={handleAssetType}
              buttonText={buttonText}
            />

            <div className={styles.range_slider}>
              <AppTextBox text={"Project Capacity"} />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "15px 0px 30px 0px",
                  // marginLeft: '-30px'
                }}
              >
                <TextField
                  style={{ width: "240px", height: "50px" }}
                  sx={{
                    width: "240px",
                    height: "50px",
                    '& .MuiOutlinedInput-root': {
                      // '& fieldset': {
                      //   borderColor: 'gray',
                      // },
                      '&:hover fieldset': {
                        borderColor: '#1F443A',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#1F443A',
                        color: 'black'
                      },
                    },
                  }}
                  autoComplete="given-name"
                  label="Enter Capacity"
                  name="name"
                  type="number"
                  value={inputCapacity}
                  onChange={(e) => setInputCapacity(e.target.value)}
                />
                <InputSelect
                  width="80px"
                  margin={0}
                  title="Unit"
                  value={selectedUnit}
                  handler={updateSliderUnit}
                  height="54px"
                  option={["MW", "kW"]}
                />
              </div>
            </div>
            <RadioSelectors
              showAllSubstations={showAllSubstations}
              handleShowAllSubstations={handleShowAllSubstations}
            />
            <QuickSelectors
              title={true}
              firstText="Demand"
              firstChecked={demandChecked}
              firstHandler={handleDemandChange}
              secondText="Generation"
              secondChecked={generationChecked}
              secondHandler={handleGenerationChange}
            />

            <QuickSelectors
              title={false}
              firstText="IQ Score"
              firstChecked={iqScore}
              firstHandler={setIqScore}
              secondText="Risk Score"
              secondChecked={riskScore}
              secondHandler={setRiskScore}
            />

            <Filters
              title="Enable Auto Layers"
              checked={autoLayers}
              updateFilter={(value) => setAutoLayers(value)}
            />

            <FilterButton
              filterButtonsArray={filterButtonsArray}
              handleFiltersButtonClick={handleFiltersButtonClick}
              filterButtonText={filterButtonText}
            />

            <div style={{ height: "20px" }} />
          </div>
        )}
        {/* {substations?.maplayerFilter && (
          <LayersModal
            open={substations?.maplayerFilter}
            setOpen={setOpenLayersModal}
          />
        )} */}

        {leftSidebarSelectedOption === "vqinfralegend" && (
          <>
          <SymbolicIcons/>
          </>
        )}
        {leftSidebarSelectedOption === "technologylegend" && <SpyMode />}
        {leftSidebarSelectedOption === "chatgptosm" && <GptOsmMode />}
        {leftSidebarSelectedOption === "layers" && <LayerSideBar />}
      </div>
    </div>
  );
};

export default LeftSidebar;
