import PropTypes from "prop-types";
import styles from "./Container1.module.css";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setMessageToThread,
} from "../../Redux/reducers/LLM";
import { useSelector } from "react-redux";
import { lambdaCallForDemo } from "../../Api/API";
import { ToastNotification } from "../../Utils/ToastNotifications";
import { Clip, SendIcon1 } from "../../SVG";

const Container1 = ({ className = "", scrollToBottom, showDetail }) => {
  const [inputValue, setInputValue] = useState("");
  const { activeThread, leftOpen } = useSelector((state) => state.llm);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  console.log(activeThread, "active Thread");

  useEffect(() => {
    scrollToBottom();
  }, []);

  const lambdaCallWithRetry = (body, retries = 3, delay = 1000) => {
    return lambdaCallForDemo(body, (retries = 3), (delay = 1000))
      .then((response) => {
        console.log(response.success, "asdads");

        if (response.success) {
          console.log(response, "new api response");

          // let data = {
          //   newMessage: {
          //     user: "assistant",
          //     message: response?.data?.content,
          //   },
          // };
          // updatethreadContent(activeThread?.[0].id, data).then((res) => {
          //   if (res.success) {
          //     console.log("data saved");
          //   }
          // });

          dispatch(
            setMessageToThread({
              threadId: activeThread?.[0].id,
              newMessage: {
                user: "assistant",
                message: response?.data?.content,
              },
            })
          );
          scrollToBottom();
        } else {
          console.log(response.success);
          ToastNotification("info", "Response not fetched ");
          setTimeout(
            () => lambdaCallWithRetry(body, retries - 1, delay),
            delay
          );
        }
      })
      .catch((err) => {
        console.log(err, "error message here");
        setTimeout(() => lambdaCallWithRetry(body, retries - 1, delay), delay);
      });
  };

  const handleClick = async () => {
    setInputValue("");
    const data = {
      user: "user",
      message: inputValue,
    };

    dispatch(
      setMessageToThread({
        threadId: activeThread?.[0].id,
        newMessage: data,
      })
    );
    // let updateApiData = {
    //   newMessage: {
    //     user: "user",
    //     message: inputValue,
    //   },
    // };
    // const updateAPI = await updatethreadContent(
    //   activeThread?.[0].id,
    //   updateApiData
    // );
    scrollToBottom();
    // Make the POST request
    let body = {
      message: inputValue,
      threadId: activeThread?.[0]?.id
    };

    lambdaCallWithRetry(body);

    // navigate(`/llm-thread`);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && inputValue.length >= 2) {
      handleClick();
    }
  };

  return (
    <section
      className={[styles.container, className].join(" ")}
      style={{
        width:
          leftOpen && showDetail
            ? "calc(100% - 660px)"
            : leftOpen && !showDetail
            ? "calc(100% - 240px)"
            : !leftOpen && showDetail
            ? "calc(100% - 478px)"
            : "calc(100% - 58px)",
        marginLeft: leftOpen ? "240px" : "58px",
      }}
    >
      <div
        className={styles.container1}
        style={{ alignItems: showDetail ? "flex-start" : "center" }}
      >
        <div className={styles.container2}>
          <div className={styles.genAIInputBoxParent}>
            <div className={styles.genAIInputBox}>
              <div className={styles.button}>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={(e) => {}}
                  multiple
                  webkitdirectory
                />
                <img
                  className={styles.microphoneIcon}
                  loading="lazy"
                  alt=""
                  src="/frame-9@2x.png"
                />
              </div>
              <div className={styles.genAIInputLabel}>
                {/* <h1 className={styles.askGenAi}>Ask Gen AI</h1> */}
                <input
                  className={styles.askGenAi}
                  placeholder="Ask Gen AI"
                  type="text"
                  value={inputValue}
                  onKeyDown={(e) => handleKeyPress(e)}
                  // ref={fileInputRef}
                  // style={{ display: "none" }}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                  }}
                  multiple
                  webkitdirectory
                />
                {/* <textarea
                  // className={styles.askAnything}
                  placeholder="Ask anything"
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                /> */}
              </div>
            </div>
            <div className={styles.frameParent}>
              <div className={styles.uiIconsWrapper}>
                <div className={styles.uiIcons}>
                  <Clip />
                </div>
              </div>
              <div
                className={styles.button1}
                onClick={handleClick}
                style={{
                  opacity: inputValue.length > 1 ? "1" : "0.5",
                }}
              >
                <SendIcon1 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Container1.propTypes = {
  className: PropTypes.string,
};

export default Container1;
