import React from "react";
import Dialog from "@mui/material/Dialog";
import { Close } from "../../SVG";
import Button from "../../Common/Button/Button";
import styles from "./styles.module.scss";
import TextArea from "../../Common/TextArea";

const CreateThreadModal = (props) => {
  const { open, setOpen, handleThread, textAreaValue, setTextAreaValue } =
    props;

  const handleClose = (e) => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={styles.saveContainer}>
          <div className={styles.headingContainer}>
            <h1 className={styles.heading}>Ask a request or make a request</h1>
            <Close onClick={handleClose} />
          </div>
          <br />
          <TextArea value={textAreaValue} setValue={setTextAreaValue} />
          {/* <p className={styles.para}>
            Move this project to your other collections.
          </p> */}

          <div className={styles.buttonContainer}>
            <Button
              height="40px"
              bgColor="#F3F4F6"
              color="#1E232E"
              radius="100px"
              borderColor="#F3F4F6"
              text="Cancel"
              handleClick={handleClose}
            />
            <Button
              height="40px"
              bgColor="#1F443A"
              color="#fff"
              radius="100px"
              borderColor="#1F443A"
              text="Send Request"
              handleClick={handleThread}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default CreateThreadModal;
