import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Button,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import styles from "./FrameComponent.module.css";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import { deleteThread, setThreadName } from "../../Redux/reducers/LLM";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import UpdateThreadModal from "../../Modals/UpdateThreadModal";
import { ToastNotification } from "../../Utils/ToastNotifications";
import { ChevronDown, LLMDetail, Subtract1 } from "../../SVG";

const FrameComponent = ({ className = "", setShowDetail, showDetail }) => {
  const { threads, activeThread } = useSelector((state) => state.llm);
  const [selectedValue, setSelectedValue] = useState(activeThread?.[0]?.name);
  const [open, setOpen] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log(activeThread?.[0]?.name, selectedValue);

  useEffect(() => {
    setSelectedValue(activeThread?.[0]?.name);
  }, [activeThread.length, threads]);

  const handleDeleteThread = (id) => {
    dispatch(deleteThread({ threadId: id }));
    navigate("/llm-threadlist");
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value === "delete") {
      handleDeleteThread(activeThread?.[0]?.id);
    }
    if (event.target.value === "rename") {
      setOpen(true);
    }
  };

  const handleUpdateThread = () => {
    if (textAreaValue && textAreaValue.length > 2) {
      dispatch(
        setThreadName({
          threadId: activeThread?.[0]?.id,
          newName: textAreaValue,
        })
      );
      setOpen(false);
      setTextAreaValue("");
    } else {
      ToastNotification("info", "Please Enter 3 character");
    }
  };

  return (
    <div className={[styles.horizontalborderParent, className].join(" ")}>
      <div className={styles.container1}>
        <div className={styles.containerChild12} />
        <div className={styles.containerInner}>
          <div className={styles.frameParent}>
            <Subtract1 area="14" />
            <FormControl
              className={styles.parent}
              variant="standard"
              sx={{
                borderTopWidth: "1px",
                borderRightWidth: "1px",
                borderBottomWidth: "1px",
                borderLeftWidth: "1px",
                borderRadius: "0px 0px 0px 0px",
                width: "81.92090395480226%",
                height: "22px",
                m: 0,
                p: 0,
                "& .MuiInputBase-root": {
                  m: 0,
                  p: 0,
                  minHeight: "22px",
                  justifyContent: "center",
                  display: "inline-flex",
                },
                "& .MuiInputLabel-root": {
                  m: 0,
                  p: 0,
                  minHeight: "22px",
                  display: "inline-flex",
                },
                "& .MuiMenuItem-root": {
                  m: 0,
                  p: 0,
                  height: "22px",
                  display: "inline-flex",
                },
                "& .MuiSelect-select": {
                  m: 0,
                  p: 0,
                  height: "22px",
                  alignItems: "center",
                  display: "inline-flex",
                },
                "& .MuiInput-input": { m: 0, p: 0 },
                "& .MuiInputBase-input": {
                  color: "#1d1d1d",
                  fontSize: 14,
                  fontWeight: "Medium",
                  // fontFamily: "Eudoxus Sans",
                  textAlign: "left",
                  p: "0 !important",
                },
              }}
            >
              <InputLabel color="primary" />
              <Select
                color="primary"
                disableUnderline
                displayEmpty
                value={selectedValue} // Set the selected value here
                onChange={handleChange} // Handle the change event
                IconComponent={() => (
                  <ChevronDown fill="#6D6D6D" />
                )}
              >
                <MenuItem value={selectedValue} sx={{ display: "none" }}>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    sx={{ marginLeft: "5px" }}
                  >
                    {activeThread?.[0]?.name}
                  </Typography>
                </MenuItem>
                <MenuItem value="rename">
                  <EditIcon color="grey" sx={{ fontSize: "20px" }} />
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    sx={{ marginLeft: "5px" }}
                  >
                    Rename
                  </Typography>
                </MenuItem>

                <MenuItem value="delete">
                  <DeleteIcon color="grey" sx={{ fontSize: "20px" }} />
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    sx={{ marginLeft: "5px" }}
                  >
                    Delete
                  </Typography>{" "}
                </MenuItem>
              </Select>
              <FormHelperText />
            </FormControl>
          </div>
        </div>
        <Button
          className={styles.buttonDialog}
          startIcon={
            <LLMDetail />
          }
          disableElevation
          variant="contained"
          onClick={() => setShowDetail(!showDetail)}
          sx={{
            textTransform: "none",
            color: "#1d1d1d",
            fontSize: "14",
            background: "#fff",
            border: "#e5e5e5 solid 1px",
            borderRadius: "8px",
            "&:hover": { background: "#fff" },
            width: 101,
            height: 40,
          }}
        >
          Details
        </Button>
      </div>
      <UpdateThreadModal
        open={open}
        setOpen={setOpen}
        handleUpdateThread={handleUpdateThread}
        textAreaValue={textAreaValue}
        setTextAreaValue={setTextAreaValue}
      />
    </div>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;
