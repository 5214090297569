import React from 'react';
import FolderIcon from '@mui/icons-material/Folder'; // Importing a folder icon from MUI icons

const tableContainerStyle = {
  width: '100%',
  maxHeight: '400px', // Set a max height for the table to allow vertical scrolling
  overflowY: 'auto',  // Enable vertical scrolling when rows exceed maxHeight
  overflowX: 'auto',  // Enable horizontal scrolling when columns exceed the width
  borderRadius: '8px',
  border: '1px solid #f2f5f4',
  backgroundColor: '#ffffff',
};
const tableStyle = {
  width: '100%',
  borderRadius: '8px',
  borderCollapse: 'collapse', // Ensure borders don't collapse between cells
  backgroundColor: '#ffffff',
};

const thStyle = {
  padding: '12px',
  textAlign: 'left',
  backgroundColor: '#e9ecef',
  color: '#495057',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  letterSpacing: '0.05em',
  borderBottom: '1px solid #f2f5f4',
};

const tdStyle = {
  padding: '12px',
  textAlign: 'left',
  color: '#6c757d',
  backgroundColor: '#fff',
  borderBottom: '1px solid #f2f5f4',
};

const trStyle = {
  backgroundColor: '#f6f8f7',
};

const trHover = {
  transition: 'background-color 0.3s ease',
};

const fileTagStyle = {
  backgroundColor: '#e6f7e6', // Light green background for file names
  color: '#027a48', // Darker green text for file names
  fontWeight: 'bold',
  padding: '4px 8px',
  borderRadius: '12px',
  display: 'inline-block',
};

const fileTypeTagStyle = {
  backgroundColor: '#fff3e6', // Light orange background for file types
  color: '#b54708', // Darker orange text for file types
  fontWeight: 'bold',
  padding: '4px 8px',
  borderRadius: '12px',
  display: 'inline-block',
};

const folderIconStyle = {
  marginRight: '8px', // Adding some space between the icon and the text
  verticalAlign: 'middle',
  color: '#027a48',
};

const DataTable = ({data}) => {

//   const data=[
//     {
//         "Topic": "AI in Healthcare",
//         "Question": "How can AI improve GP admin processes?",
//         "LLM Response": "AI can automate repetitive tasks and streamline",
//         "Action Required": "Build MVP for admin workflow",
//         "Priority Level": "High",
//         "Action Required2": "Build MVP for admin workflow",
//         "Priority Level2": "High",
//         "Action Required2": "Build MVP for admin workflow",
//         "Priority Level2": "High"
//     },
//     {
//         "Topic": "Data Security",
//         "Question": "What are the challenges in securing AI data?",
//         "LLM Response": "Ensuring data privacy and regulatory compliance",
//         "Action Required": "Implement encryption and GDPR",
//         "Priority Level": "Critical",
//         "Action Required2": "Build MVP for admin workflow",
//         "Priority Level2": "High",
//         "Action Required2": "Build MVP for admin workflow",
//         "Priority Level2": "High"
//     },
//     {
//         "Topic": "UI/UX Design",
//         "Question": "How can we simplify user interfaces?",
//         "LLM Response": "Use minimalistic design focused on user tasks",
//         "Action Required": "Redesign using v0.dev tools",
//         "Priority Level": "Medium",
//         "Action Required2": "Build MVP for admin workflow",
//         "Priority Level2": "High",
//         "Action Required2": "Build MVP for admin workflow",
//         "Priority Level2": "High"
//     },
//     {
//         "Topic": "Model Fine-tuning",
//         "Question": "What's the best approach for fine-tuning?",
//         "LLM Response": "Focus on domain-specific data for better results",
//         "Action Required": "Continue LLM fine-tuning",
//         "Priority Level": "High",
//         "Action Required2": "Build MVP for admin workflow",
//         "Priority Level2": "High",
//         "Action Required2": "Build MVP for admin workflow",
//         "Priority Level2": "High"
//     },
//     {
//         "Topic": "Team Management",
//         "Question": "How to manage remote tech teams effectively?",
//         "LLM Response": "Improve communication and set clear milestones",
//         "Action Required": "Schedule weekly check-ins",
//         "Priority Level": "Medium",
//         "Action Required2": "Build MVP for admin workflow",
//         "Priority Level2": "High",
//         "Action Required2": "Build MVP for admin workflow",
//         "Priority Level2": "High"
//     }
// ]
  console.log("Data received by DataTable:", data);

  if (!Array.isArray(data)) {
    return <div>Error: Data is not in the expected format.</div>;
  }

  if (data.length === 0) {
    return <div>No data available</div>;
  }

  const headers = Object.keys(data[0]);

  return (
    <div style={tableContainerStyle}>
    <table style={tableStyle}>
      <thead>
        <tr style={trStyle}>
          {headers.map((header, index) => (
            <th key={index} style={thStyle}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex} style={trHover}>
            {headers.map((header, colIndex) => {
              let content = row[header];
              let style = tdStyle;

              if (header === 'File') {
                content = <span style={fileTagStyle}>{row[header]}</span>;
              } else if (header === 'File Type') {
                content = <span style={fileTypeTagStyle}>{row[header]}</span>;
              } else if (header === 'Folder' && row[header]) {
                content = (
                  <>
                    <FolderIcon style={folderIconStyle} />
                    {row[header]}
                  </>
                );
              }

              return (
                <td key={colIndex} style={style}>
                  {content}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
    </div>  
  );
};

export default DataTable;
