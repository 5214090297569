import { useSelector } from "react-redux";
import Header from "../../../../Components/Header";
import Container from "../../../../Components/Shell/Infra3Tender/Container";
import OrgSelectorContainer from "../../../../Components/Shell/Infra3Tender/OrgSelectorContainer";
import styles from "./ThreadList.module.css";
import { useDispatch } from "react-redux";
import {
  deleteThread,
  setActiveThread,
  setThreads,
} from "../../../../Components/Redux/reducers/LLM";
import { useNavigate } from "react-router-dom";
import { getAllThreads } from "../../../../Components/Api/API";
import { Clock, Trash04 } from "../../../../Components/SVG";

const ThreadList = () => {
  const { threads, leftOpen } = useSelector((state) => state.llm);
  const { userDetail } = useSelector((state) => state.users);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDeleteThread = (id) => {
    deleteThread(id).then((res) => {
      if (res.success) {
        getAllThreads(userDetail.user.id)
          .then((res) => {
            console.log(res.data, "threads");
            if (res.data) {
              dispatch(setThreads(res.data));
              dispatch(setActiveThread(res.data[res.data.length - 1]));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  // const handleDeleteThread1 = (id) => {
  //   dispatch(deleteThread({ threadId: id }));
  // };

  const handleThreadClick = (thread) => {
    dispatch(setActiveThread(thread));
    navigate("/llm-tender-thread");
  };

  return (
    <div className={styles.container}>
      <main className={styles.backgroundWrapper}>
        <Header />
      </main>
      <div className={styles.backgroundParent}>
        <div className={styles.background}>
          <Container />
        </div>
        <div
          className={styles.mainSection}
          style={{
            width: leftOpen ? "calc(100% - 240px)" : "calc(100% - 58px)",
          }}
        >
          <OrgSelectorContainer />
          <div className={styles.container9}>
            <div className={styles.container10}>
              <div className={styles.container11}>
                <div className={styles.threadItem}>
                  {/* <div className={styles.buttonDialog1}>
                                        <Searchmd />
                                        <div className={styles.linkLabels}>
                                            <div className={styles.syncs}>Search your threads...</div>
                                        </div>
                                    </div> */}
                  <div className={styles.threadPreviews}>
                    {threads?.length === 0 && (
                      <div className={styles.toAssessTheContainer}>
                        <p className={styles.toAssessThe}>
                          There is not any Thread Available now.
                        </p>
                        <p className={styles.toAssessThe}>&nbsp;</p>
                        <p className={styles.toAssessThe}>
                          Please create a new thread.
                        </p>
                      </div>
                    )}
                    {[...threads]
                      ?.sort(
                        (a, b) =>
                          new Date(b.created_at) - new Date(a.created_at)
                      )
                      ?.map((item, index) => {
                        return (
                          <div className={styles.threadContent}>
                            <div className={styles.evEnergyProject}>
                              {item.name}
                            </div>
                            <div
                              className={styles.toAssessTheContainer}
                              onClick={() => handleThreadClick(item)}
                            >
                              <p className={styles.toAssessThe}>
                                {item.content?.[1]?.message?.length > 300
                                  ? `${item.content?.[1]?.message?.substring(
                                    0,
                                    300
                                  )}...`
                                  : item.content?.[1]?.message}
                              </p>
                              <p className={styles.toAssessThe}>&nbsp;</p>
                            </div>
                            <div className={styles.threadMetadata}>
                              <div className={styles.timestampContainer}>
                                <Clock />
                                <div
                                  className={styles.sep82024At1207AmGmt4Wrapper}
                                >
                                  <div className={styles.sep82024}>
                                    Sep 8, 2024 at 12:07 AM GMT+4
                                  </div>
                                </div>
                              </div>
                              <div
                                className={styles.deleteContainer}
                                onClick={() => handleDeleteThread(item.id)}
                              >
                                <Trash04 />
                              </div>
                            </div>
                          </div>
                        );
                      })}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreadList;
