import { createSlice } from "@reduxjs/toolkit";

const filterSlice = createSlice({
  name: "filters",
  initialState: {
    searchValue: "",
    minRadius: "",
    maxRadius: "",
    minCapacity: "",
    maxCapacity: "",
    demand: true,
    generation: false,
    riskAndIQ: [],
    assetType: "",
    riskScore: "",
    mapLoader: false,
    source: "",
    voltageLine: "",
    showAllSubstations: "true",
    enableLayers:true,
    search_by: 'Address',
    drawnPolygon: null,
    drawnLine: null,
    currentCenter: null,
    selectedLayers: false
  },
  reducers: {
    setSearchValue: (state, action) => {
      return { ...state, searchValue: action.payload };
    },
    setFilterValues: (state, action) => {
      return { ...state, ...action.payload };
    },
    setMapLoader: (state, action) => {
      return { ...state, mapLoader: action.payload };
    },
    setSearchTypeAction: (state, action) => {
      return { ...state, search_by: action.payload };
    },
    setDrawnPolygon: (state, action) => {
      return { ...state, drawnPolygon: action.payload };
    },
    setCurrentCenter: (state, action) => {
      return { ...state, currentCenter: action.payload };
    },
    setDrawnLine: (state, action) => {
      return { ...state, drawnLine: action.payload };
    },
    setCurrentCenter: (state, action) => {
      return { ...state, currentCenter: action.payload };
    },
    setSaveSelectedLayers: (state, action) => {
      return { ...state, selectedLayers: action.payload };
    },
  },
});

export const { setSearchValue, setFilterValues, setRiskScore, setMapLoader,setSearchTypeAction,setDrawnPolygon,setCurrentCenter,setDrawnLine,setSaveSelectedLayers } =
  filterSlice.actions;
export default filterSlice.reducer;
