import React, { useState } from "react";
import Header from "../../Components/Header";
import { Button } from "@mui/material";

const Upload = () => {
  const [fileContent, setFileContent] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        setFileContent(content);
        console.log(content); // To verify the file content
        // Call the function to send the file content to the backend if needed
      };
      reader.readAsText(file); // This assumes the CSV file is encoded in UTF-8
    }
  };

  const handleUpload = async () => {
    if (fileContent) {
      try {
        const response = await fetch("http://localhost:3001/api/v1/project/uploadCsv", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ csvData: fileContent }),
        });

        const result = await response.json();
        console.log(result.message);
      } catch (err) {
        console.error("Error uploading file:", err);
      }
    } else {
      console.error("No file content to upload.");
    }
  };
  return (
    <>
      <Header />
      <div>
        <Button variant="contained" component="label">
          Upload File
          <input type="file" hidden onChange={handleFileChange} />
        </Button>
        <Button variant="contained" onClick={handleUpload}>
          Send to Server
        </Button>
      </div>
    </>
  );
};
export default Upload;
