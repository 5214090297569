import React from "react";
import { useSelector } from "react-redux";
import Header from "../Header";
import RightSidebar from "../Sidebar/RightSidebar";
import LeftSidebar from "../Sidebar/LeftSidebar/LeftSidebar";
import PrivateWire from "../Sidebar/RightSidebar/PrivateWire";
import SecondaryAquisition from "../Sidebar/RightSidebar/SecondaryAquisition";
// import TransformerAnalysis from "../Sidebar/RightSidebar/TransformerAnalysis";

const MapWrapper = ({ children }) => {
  const projects = useSelector((state) => state.projects);

  return (
    <>
      <div>
        <Header />
        <div>
          <LeftSidebar />
        </div>
        <div>{children}</div>
        <div>
          {projects.transmission === "Private Wire Scheme" ? (
            <PrivateWire />
          ) : projects.transmission === "Secondary Acquisitions" ? (
            <SecondaryAquisition />
          ) : projects.transmission === "Transformer Analysis" ? (
            <RightSidebar />
          ) : (
            <RightSidebar />
          )}
        </div>
      </div>
    </>
  );
};

export default MapWrapper;
