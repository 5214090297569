import { useRef, useState, useEffect } from "react";
import Container from "../../../../Components/Shell/Infra3/Container";
import FrameComponent from "../../../../Components/Shell/Infra3/FrameComponent";
import FrameComponent1 from "../../../../Components/Shell/Infra3/FrameComponent1";
import Container1 from "../../../../Components/Shell/Infra3/Container1";
import styles from "./Thread.module.css";
import Header from "../../../../Components/Header";
import ReportComponent from "../../../../Components/Shell/Infra3/ReportComponent";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// import { useSelector } from "react-redux";

const Thread = () => {
  const [showDetail, setShowDetail] = useState(false);
  const messagesContainerRef = useRef(null);
  const navigate = useNavigate();

  const { leftOpen } = useSelector((state) => state.llm);
  const { activeThread } = useSelector((state) => state.llm);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (messagesContainerRef.current) {
        console.log("scrollHeight:", messagesContainerRef.current.scrollHeight);
        console.log(
          "scrollTop before:",
          messagesContainerRef.current.scrollTop
        );
        messagesContainerRef.current.scrollTop =
          messagesContainerRef.current.scrollHeight;
        console.log("scrollTop after:", messagesContainerRef.current.scrollTop);
      }
    }, 100);
  };

  useEffect(() => {
    if (
      activeThread?.length === 0 ||
      Object.keys(activeThread?.[0]).length === 0
    ) {
      navigate("/llm");
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.root}>
      <div className={styles.background}>
        <div className={styles.verticalborder}>
          <Container />
        </div>
      </div>
      <header className={styles.backgroundParent}>
        <Header />
        <FrameComponent showDetail={showDetail} setShowDetail={setShowDetail} />
      </header>
      <div
        className={styles.frameComponentParent}
        style={{
          justifyContent: showDetail ? "space-between" : "center",
          width: leftOpen ? "calc(100% - 240px)" : "calc(100% - 58px)",
          marginLeft: leftOpen ? "240px" : "58px",
        }}
        ref={messagesContainerRef}
      >
        <FrameComponent1 />
        {showDetail && <ReportComponent />}
      </div>
      <Container1 showDetail={showDetail} scrollToBottom={scrollToBottom} />
      <div className={styles.overlay} />
    </div>
  );
};

export default Thread;
