import { makeRequest } from "./instance";

export const getAllSubstations = () => {
  return makeRequest("get", "/substations/all");
};
export const userSubstationClicked = (body) => {
  return makeRequest("post", "/admin/substation_clicked", body);
};
export const userSavedSubstation = (body) => {
  return makeRequest("post", "/admin/add_substation_saved", body);
};
export const userRemovedSubstation = (body) => {
  return makeRequest("post", "/admin/remove_substation_saved", body);
};
export const updateActiveLayers = (body) => {
  return makeRequest("post", "/admin/update_enabled_layers", body);
};
export const createCheckoutSession = (body) => {
  return makeRequest("post", "/create-checkout-session", body);
};
export const saveProject = (body) => {
  return makeRequest("post", "/project/create", body);
};
export const getHabitat = (body) => {
  return makeRequest("post", "/map/get-habitat-bng", body);
};
export const uploadProjectCsv = (body) => {
  return makeRequest("post", "/project/uploadCsv", body);
};
export const saveBulkProject = (body) => {
  return makeRequest("post", "/project/create-bulk", body);
};
export const moveProject = (body) => {
  return makeRequest("put", `project/${body.projectId}/move`, body);
};
export const shareProject = (id, body) => {
  return makeRequest("post", `project/${id}/share`, body);
};
export const generateSharableLink = (body) => {
  return makeRequest("post", "project/generateSharableLink", body);
};
export const deleteProject = (id) => {
  return makeRequest("delete", `/project/${id}`);
};
export const fetchAllProject = (body) => {
  return makeRequest("post", "/projects/all", body);
};
export const fetchAllDeletedProject = (page) => {
  return makeRequest("get", `/project/get-deleted-projects?page=${page}`);
};
export const restoreDeletedProject = (id) => {
  return makeRequest("get", `/project/restore-project/${id}`);
};
export const fetchAllUsers = () => {
  return makeRequest("get", "/user/all");
};
export const createCollection = (body) => {
  return makeRequest("post", "/collection/create", body);
};
export const fetchAllCollection = () => {
  return makeRequest("get", "/collection/all");
};
export const fetchCollectionProjects = (id, page, search, asset_type, sort_by, headrom_type, status, list_view, count, min_capacity, max_capacity, min_radius, max_radius) => {
  return makeRequest("get", `/collection/${id}?page=${page}&search=${search}&asset_type=${asset_type === "None" ? "" : asset_type}&sort_by=${sort_by === "None" ? "" : sort_by}&headrom_type=${headrom_type === "None" ? "" : headrom_type}&status=${status === "None" ? "" : status}&list_view=${list_view === true ? "true" : "false"}&count=${count}&min_capacity=${min_capacity}&max_capacity=${max_capacity}&min_radius=${min_radius === undefined ? "" : min_radius}&max_radius=${max_radius === undefined ? "" : max_radius}`);
};
export const fetchUserProjects = (body) => {
  return makeRequest("get", "/project/user-projects", body);
};
export const fetchClosestSubstations = (body) => {
  return makeRequest("post", "/closest-substations", body);
};
export const fetchClosestLocalPlanningProjects = (body) => {
  return makeRequest("post", "/closest-local-planning-projects", body);
};
export const sendEmail = (body) => {
  return makeRequest("post", "/send-email", body);
};
// export const getUserProfile = (id) => {
//   return makeRequest("get", `/user-profile/get/${id}`);
// };
// export const updateProfile = (body) => {
//   return makeRequest("post", "/user-profile/update", body);
// };
export const modifyPlan = (body) => {
  return makeRequest("post", "/modify-plan", body);
};
export const getOverlayFeatures = (body) => {
  return makeRequest("post", "/map/get-overlay-features", body);
};
export const verifyCustomer = (body) => {
  return makeRequest("post", "/auth/verify", body);
};
export const uploadImage = (body) => {
  return makeRequest("post", "/image-upload", body);
};
export const getCoordinates = (body) => {
  return makeRequest("post", "/get-coordinates", body);
};
export const deleteCollections = (id, value) => {
  return makeRequest("delete", `/collection/${id}/${value}`);
};
export const shareColectionToUsers = (id, body) => {
  return makeRequest("post", `collection/${id}/share`, body);
};
export const updateUserProfile = (id, body) => {
  return makeRequest("put", `/user/${id}`, body);
};
export const updateProject = (id, body) => {
  return makeRequest("put", `/project/${id}`, body);
};
export const getSharedByYouProjects = (page, search, asset_type, sort_by, headrom_type, status, min_capacity, max_capacity, min_radius, max_radius) => {
  return makeRequest("get", `project/shared/shared_by_you?page=${page}&search=${search}&asset_type=${asset_type === "None" ? "" : asset_type}&sort_by=${sort_by === "None" ? "" : sort_by}&headrom_type=${headrom_type === "None" ? "" : headrom_type}&status=${status === "None" ? "" : status}&min_capacity=${min_capacity}&max_capacity=${max_capacity}&min_radius=${min_radius}&max_radius=${max_radius}`);
};
export const getSharedWithYouProjects = (page, search, asset_type, sort_by, headrom_type, status, min_capacity, max_capacity, min_radius, max_radius) => {
  return makeRequest("get", `project/shared/shared_with_you?page=${page}&search=${search}&asset_type=${asset_type === "None" ? "" : asset_type}&sort_by=${sort_by === "None" ? "" : sort_by}&headrom_type=${headrom_type === "None" ? "" : headrom_type}&status=${status === "None" ? "" : status}&min_capacity=${min_capacity}&max_capacity=${max_capacity}&min_radius=${min_radius}&max_radius=${max_radius}`);
};
export const getSharedWithUrlProjects = (page, search, asset_type, sort_by, headrom_type, status, min_capacity, max_capacity, min_radius, max_radius) => {
  return makeRequest("get", `project/shared/shared_with_url?page=${page}&search=${search}&asset_type=${asset_type === "None" ? "" : asset_type}&sort_by=${sort_by === "None" ? "" : sort_by}&headrom_type=${headrom_type === "None" ? "" : headrom_type}&status=${status === "None" ? "" : status}&min_capacity=${min_capacity}&max_capacity=${max_capacity}&min_radius=${min_radius}&max_radius=${max_radius}`);
};
export const revokeProjectLinkAccess = (id) => {
  return makeRequest("delete", `/project/revokeLinkAccess/${id}`);
};
export const updateLinkExpiry = (body) => {
  return makeRequest("post", "/project/updateLinkExpiry", body);
};
export const getLayers = (body) => {
  return makeRequest("post", "/map/get-layers", body);
};
export const getProjectDetail = (id) => {
  return makeRequest("get", `project/${id}`);
};
export const getProjectStatuses = () => {
  return makeRequest("get", "status/getAll");
};
export const updateProjectStatus = (body) => {
  return makeRequest("post", "project/change-status", body);
};
export const getNearestNcpr = (body) => {
  return makeRequest("post", "/map/get-closest-ncpr", body);
};
export const getEnclosingPolygon = (body) => {
  return makeRequest("post", "/map/get-enclosing-polygon", body);
};
export const getDirections = (body) => {
  return makeRequest("post", "/map/get-directions", body);
};
export const getGptOsmData = (body, options) => {
  return makeRequest("post", "/map/gpt-osm-data", body, options);
};
export const addSearch = (body) => {
  return makeRequest("post", "/admin/add_search", body);
};
export const getUserProfile = (id, body) => {
  return makeRequest("get", `/user/${id}`, body);
};
export const getUserChat = () => {
  return makeRequest("get", "/message/chats");
};
export const fetchPrometheusData = (body) => {
  return makeRequest("post", "/closest-substations/prometheus-ai", body);
}
export const uploadChatImage = (body) => {
  return makeRequest("post", "/image-upload/chat", body);
}
export const getRecipientChat = (id) => {
  return makeRequest("get", `/message/messages/${id}`);
};
export const readMessages = (body) => {
  return makeRequest("post", "/message/readMessage", body);
};
export const readGroupMessages = (body) => {
  return makeRequest("post", "/groups/readMessage", body);
};
export const createGroup = (body) => {
  return makeRequest("post", "/groups/createGroup", body);
};
export const updateGroup = (id, body) => {
  return makeRequest("put", `/groups/${id}`, body);
};
export const getAllGroups = () => {
  return makeRequest("get", "/groups/getAll");
};
export const getGroupMessages = (id) => {
  return makeRequest("get", `/groups/getGroupMessages/${id}`);
};
export const deleteGroup = (id) => {
  return makeRequest("delete", `/groups/${id}`);
};
export const inviteUserIntoGroup = (body) => {
  return makeRequest("post", "/groups/invite", body);
};
export const acceptOrRejectInvitation = (body) => {
  return makeRequest("post", "/groups/invitationResponse", body);
};
export const updateUserPermission = (body) => {
  return makeRequest("put", "/groups/updatePermission", body);
};
export const removeGroupMember = (groupId, userId) => {
  return makeRequest("delete", `/groups/removeGroupMember/${groupId}/${userId}`);
};
export const getCompanyGroup = () => {
  return makeRequest("get", "/groups/getCompanyGroup");
};
export const getCompanyGroupMessages = (id) => {
  return makeRequest("get", `/groups/getCompanyGroupMessages/${id}`);
};
export const getAllNotifications = () => {
  return makeRequest("get", "/notifications");
};
export const readSingleNotification = (id) => {
  return makeRequest("put", `/notifications/${id}`);
};
export const readAllNotification = () => {
  return makeRequest("put", "/notifications/readAll");
};
export const deleteNotification = (id) => {
  return makeRequest("delete", `/notifications/${id}`);
};
export const fetchDataForDemo13 = (body) => {
  return makeRequest("post", "/closest-substations/by-demo-13", body);
};
export const fetchConnectedSubstations = (body) => {
  return makeRequest("post", "/closest-substations/connection-analysis", body);
};
export const fetchOldClosestSubstations = (body) => {
  return makeRequest("post", "/closest-substations", body);
};
export const lambdaCallForDemo = (body) => {
  return makeRequest("post", "/communicate/testAPi", body);
};

export const textEditorCallForDemo = (body) => {
  return makeRequest("post", "/communicate/take-suggestions", body);
};
export const getAllThreads = (userId) => {
  return makeRequest("get", `/thread/get/${userId}`);
};
export const createThread = (body) => {
  return makeRequest("post", `/thread/create`, body);
};
export const deleteThread = (id) => {
  return makeRequest("delete", `/thread/delete/${id}`);
};
export const updatethreadContent = (id, body) => {
  return makeRequest("post", `/thread/update/${id}`, body);
};