import PropTypes from "prop-types";
import styles from "./Container2.module.css";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setActiveThread,
  setMessageToThread,
  setThreads,
} from "../../Redux/reducers/LLM";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastNotification } from "../../Utils/ToastNotifications";
import { createThread, getAllThreads, lambdaCallForDemo, updatethreadContent } from "../../Api/API";
import { Clip, SendIcon1 } from "../../SVG";

const Container2 = ({ className = "" }) => {
  const [inputValue, setInputValue] = useState("");
  const [disabled, setDisabled] = useState(false);

  const { threads, activeThread } = useSelector((state) => state.llm);
  const { userDetail } = useSelector((state) => state.users);

  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log(threads, activeThread, "asasasas");

  const handleClick = async () => {
    setDisabled(true)
    if (inputValue.length < 4) {
      ToastNotification("info", "Please enter at least 3 characters");
      return;
    }

    let activeThread = {};
    let data = {
      name: inputValue,
      content: [
        {
          user: "user",
          message: inputValue,
        },
      ],
    };
    try {
      // Create a new thread
      const createRes = await createThread(data);

      if (createRes.success === true) {
        // dispatch(updateThread(createRes.data));
        // console.log(createRes.data, "Thread created successfully");

        // Fetch all threads after creating a new thread
        const threadsRes = await getAllThreads(userDetail.user.id);

        if (threadsRes.data) {
          console.log("Fetched all threads:", threadsRes.data);

          activeThread = threadsRes.data[threadsRes.data.length - 1]; // Set the latest thread as active
          console.log(activeThread, "active here");

          dispatch(setThreads(threadsRes.data));
          dispatch(setActiveThread(activeThread));
        }
      }

      setInputValue("");
      navigate("/llm-tender-thread");

      const body = {
        message: inputValue,
      };

      // Make a call to lambda function
      const lambdaResponse = await lambdaCallForDemo(body);

      if (lambdaResponse.data) {
        // console.log(activeThread, "activeThread");
        const threadId = activeThread?.id; // Use the correct ID

        let data = {
          newMessage: {
            user: "assistant",
            message: lambdaResponse?.data?.content,
          },
        };

        // console.log(activeThread.id, "threadId");

        // Update the thread content with the assistant's message
        const updateRes = await updatethreadContent(threadId, data);

        if (updateRes.success) {
          console.log("Thread content updated successfully");
        }

        // Update the Redux store with the new message
        dispatch(
          setMessageToThread({
            threadId: threadId,
            newMessage: {
              user: "assistant",
              message: lambdaResponse?.data?.content,
            },
          })
        );
      }
    } catch (err) {
      console.log("Error occurred:", err);
    }
  };

  return (
    <section className={[styles.container, className].join(" ")}>
      <div className={styles.container1}>
        <div className={styles.container2}>
          <div className={styles.genAIInputBoxParent}>
            <div className={styles.genAIInputBox}>
              <div className={styles.button}>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={(e) => { }}
                  multiple
                  webkitdirectory
                />
                <img
                  className={styles.microphoneIcon}
                  loading="lazy"
                  alt=""
                  src="/frame-9@2x.png"
                />
              </div>
              <div className={styles.genAIInputLabel}>
                {/* <h1 className={styles.askGenAi}>Ask Gen AI</h1> */}
                {/* <input
                  className={styles.askGenAi}
                  placeholder="Ask Gen AI"
                  type="text"
                  value={inputValue}
                  // ref={fileInputRef}
                  // style={{ display: "none" }}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                  }}
                  multiple
                  webkitdirectory
                /> */}
                <textarea
                  // className={styles.askAnything}
                  placeholder="Ask Gen AI"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  style={{
                    outline: "none",
                    border: "0px",
                    height: "170px",
                    background: "#F8F8F8",
                    width: "552px",
                    padding: "10px",
                    fontSize: "0.875rem",
                    lineHeight: "1.25rem",
                    resize: "none",
                  }}
                // value={userInput}
                // onChange={(e) => setUserInput(e.target.value)}
                />
                {/* <TextArea/> */}
              </div>
            </div>
            <div className={styles.frameParent}>
              <div className={styles.uiIconsWrapper}>
                <div className={styles.uiIcons}>
                  <Clip />
                </div>
              </div>
              <div
                className={styles.button1}
                onClick={handleClick}
                disabled={disabled}
                style={{
                  opacity: inputValue.length > 3 ? "1" : "0.5",
                  cursor: disabled ? 'not-allowed' : 'pointer'
                }}
              >
                <SendIcon1 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Container2.propTypes = {
  className: PropTypes.string,
};

export default Container2;
